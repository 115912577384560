export const allKey = 'All';

export const createHashMap = (arr = [], placeholder = '') => {
  const result = { [placeholder]: allKey };
  arr
    .filter((el) => el)
    .forEach((el) => {
      result[el] = el;
    });
  return result;
};
