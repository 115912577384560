import { Fragment, useState, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import { useTheme } from 'styled-components';
import { useSelector } from 'react-redux';

import { Card, CardHeading, DarkText, Row, Column, ColorBlock, CrossLine, ErrorKpi, Absolute, Button, ModalBg } from 'components/common';
import { BarSkeleton } from './index';
import { Expand } from 'components/icons';
import { createMultipleBarOptions } from 'components/common/barChart/helpers';
import { useToggle } from 'helpers/hooks';
import Api from 'api';

export const MultipleBar = ({ apiMethodName = 'getUsersActivityByDayBar', width = '100%' }) => {
  const filters = useSelector((s) => s.filters);
  const [
    {
      data: { title, plots },
      load,
      error,
    },
    setKpi,
  ] = useState({ load: true, error: '', data: { title: '', plots: null } });

  useEffect(() => {
    const controller = new AbortController();
    setKpi((prev) => ({ ...prev, load: true, error: '' }));
    Api[apiMethodName](filters, controller.signal)
      .then((data) => setKpi((prev) => ({ ...prev, data, load: false, error: '' })))
      .catch((error) => {
        if (error !== 'canceled') {
          setKpi((prev) => ({ ...prev, load: false, error }));
        }
      });

    return () => controller.abort();
  }, [filters, apiMethodName]);

  const [fullView, setFull] = useToggle();

  const { blue, greenLight, red, gold, violet, green, orange, violetLight, greyTert, blueDark } = useTheme();
  const defaultColors = [blue, greenLight, red, gold, violet, green, orange, violetLight, greyTert, blueDark];

  if (load) return <BarSkeleton {...{ width }} />;

  if (error) return <ErrorKpi width={width} message={error} />;

  const plotsKey = Object.keys(plots || {})[0] || 'Performance';

  return (
    <Fragment>
      <Card h='522px' visible m='0 0 30px' w={width} p='16px 20px 24px'>
        <Row>
          <CardHeading>{title}</CardHeading>
        </Row>
        <CrossLine parentPadding={20} />
        <Row m='5px auto 0' position='relative' h='350px' w='100%'>
          <Bar {...createMultipleBarOptions(plots[plotsKey], false, defaultColors)} />
          <Absolute>
            <Button no_text is_fill w='24px' z={3} h='24px' onClick={setFull}>
              <Expand />
            </Button>
          </Absolute>
        </Row>
        <Row hidden isWrap m='8px 0 0'>
          {[...plots[plotsKey].datasets].slice(0, 10).map((el, index) => (
            <Row
              w={plots[plotsKey].datasets.length > 10 ? 'calc(20% - 10px)' : `calc(${100 / Math.ceil(plots[plotsKey].datasets.length / 2)}% - 10px)`}
              m='16px 0 0'
              align='center'
              justify='flex-start'
              key={el.label + index}
            >
              <ColorBlock bg={defaultColors[index]} />
              <Column w='calc(100% - 37px)'>
                <DarkText fs={12}>{el.label}</DarkText>
              </Column>
            </Row>
          ))}
          <Row w={plots[plotsKey].datasets.length > 10 ? 'calc(20% - 10px)' : `calc(${100 / Math.ceil(plots[plotsKey].datasets.length / 2)}% - 10px)`} />
          <Row w={plots[plotsKey].datasets.length > 10 ? 'calc(20% - 10px)' : `calc(${100 / Math.ceil(plots[plotsKey].datasets.length / 2)}% - 10px)`} />
          <Row w={plots[plotsKey].datasets.length > 10 ? 'calc(20% - 10px)' : `calc(${100 / Math.ceil(plots[plotsKey].datasets.length / 2)}% - 10px)`} />
          <Row w={plots[plotsKey].datasets.length > 10 ? 'calc(20% - 10px)' : `calc(${100 / Math.ceil(plots[plotsKey].datasets.length / 2)}% - 10px)`} />
        </Row>
      </Card>
      {fullView && (
        <ModalBg>
          <Card h='100%' p='30px'>
            <Row m='5px auto 0' position='relative' h='100%' w='100%'>
              <Bar {...createMultipleBarOptions(plots[plotsKey], true, defaultColors)} />
              <Absolute>
                <Button no_text is_fill w='24px' z={3} h='24px' onClick={setFull}>
                  <Expand />
                </Button>
              </Absolute>
            </Row>
          </Card>
        </ModalBg>
      )}
    </Fragment>
  );
};
