import styled, { css } from 'styled-components';

import { Row } from 'components/common/containers';
import { flexCenter, border4, transition } from 'components/common/mixins';

const border = css`
  border: 1px solid ${({ theme: { greyBorder } }) => greyBorder};
  border-collapse: collapse;
`;

export const TableContainer = styled.div`
  width: 100%;
`;

export const Table = styled.table`
  ${border}
  background: ${({ theme: { white } }) => white};
  color: ${({ theme: { blackPrimary } }) => blackPrimary};
`;

export const TH = styled.th`
  ${border}
  white-space: nowrap;
  padding: 15px;
  font-size: 14px;
  &.sorted {
    cursor: pointer;
    svg {
      ${transition}
      opacity: ${({ is_sorted }) => (is_sorted ? 1 : 0)};
      margin-left: 7px;
      transform: ${({ is_asc }) => (is_asc ? 'rotate(180deg)' : 'none')};
    }
    &:hover {
      background: ${({ theme: { greyBorder } }) => greyBorder};
    }
  }
  .heading-span {
    max-width: calc(100% - 19px);
    display: block;
    word-wrap: break-word;
    white-space: normal;
  }
  &.start-align {
    text-align: left;
    vertical-align: top;
  }
`;
export const TD = styled.td`
  ${border}
  padding: 5px 10px;
  vertical-align: top;
  font-size: 12px;
  &.break-word {
    word-break: break-all;
  }
  &.wallet-cell {
    cursor: pointer;
    svg {
      margin-left: 12px;
      opacity: 0;
    }
    &:hover {
      svg {
        opacity: 1;
      }
    }
  }
  &.problem {
    position: relative;
    &::before {
      content: '';
      position: absolute;
      width: 2px;
      height: 100%;
      background: red;
      left: 0;
      top: 0;
    }
  }
`;

export const PaginatorRow = styled(Row)`
  .paginator {
    padding: 0;
    display: flex;
    > li {
      ${border4}
      ${transition}
      min-width: 24px;
      height: 24px;
      border: 1px solid;
      border-color: ${({ theme: { blue } }) => blue};
      color: ${({ theme: { blue } }) => blue};
      margin-right: 4px;
      list-style-type: none;
      cursor: pointer;
      &:last-child {
        margin-right: 0;
      }
      &.selected {
        color: #fff;
        background: ${({ theme: { blue } }) => blue};
      }
      > a {
        ${flexCenter}
        min-width: 22px;
        height: 22px;
        font-size: 14px;
        padding: 0 5px;
      }
    }
  }
`;

export const CircleImage = styled.img`
  width: 16px;
  height: 16px;
  cursor: pointer;
  border-radius: 50%;
`;
