import styled, { css } from 'styled-components';

import { textStyles, Card, Row, Column, border4, transition, noUserSelect, textToDot } from 'components/common';

export const fields = css`
  ${textStyles}
  ${border4}
  line-height: 135%;
  letter-spacing: -0.02em;
  width: 100%;
  height: 36px;
  border: 1px solid ${({ theme: { greyBorder } }) => greyBorder};
  padding: 5px 15px;
  cursor: pointer;
`;

export const SelectColumn = styled(Column)`
  position: relative;
`;

export const SelectBlock = styled(Row)`
  ${fields}
  ${transition}
  ${noUserSelect}
  padding-right: 30px;
  z-index: ${({ is_open }) => (is_open ? 1002 : 999)};
  border-color: ${({ is_open, theme: { blue, greyBorder } }) => (is_open ? blue : greyBorder)};
  background: transparent;
  > span {
    ${textToDot}
    width:100%;
    text-align: left;
  }
  &::after {
    ${transition}
    transform: ${({ is_open }) => (is_open ? 'rotate(180deg)' : 'none')};
    content: '';
    position: absolute;
    right: 10px;
    top: 10px;
    background-image: url('/assets/arrow.svg');
    width: 16px;
    height: 16px;
  }
`;

export const Options = styled(Card)`
  ${transition}
  ${noUserSelect}
  border: 1px solid ${({ theme: { blue } }) => blue};
  position: absolute;
  top: ${({ is_open }) => (is_open ? '38px' : '1px')};
  left: 0;
  z-index: 1003;
  box-shadow: 0 2px 12px 0 rgb(35 35 51 / 50%);
  max-height: 300px;
  display: ${({ is_open }) => (is_open ? 'block' : 'none')};
  overflow: auto;
`;

export const Option = styled(Row)`
  height: 32px;
  cursor: pointer;
  background: ${({ isActive, theme: { blueDark, white } }) => (isActive ? blueDark : white)};
  ${transition}
  h3 {
    ${transition}
    color: ${({ isActive, theme: { white, blackTert } }) => (isActive ? white : blackTert)};
  }
  &:hover {
    background: ${({ theme: { blue } }) => blue};
    h3 {
      color: ${({ theme: { white } }) => white};
    }
  }
`;

export const Input = styled.input`
  ${fields}
  border: 1px solid ${({ theme: { blue } }) => blue};
  outline: none;
  cursor: auto;
  padding-right: 33px;
`;
