import moment from 'moment';

import { createLegend, createScales, tooltip, createGradient, createRandomHex } from 'helpers';
import { THEME } from 'theme';

export const createLineOptions = (obj, fullView, colors = [], isDateLabels = true, format = 'DD MMM YYYY') => {
  const labels = obj.labels.map((l) => (isDateLabels ? moment(l).format(format) : l));
  const datasets = JSON.parse(JSON.stringify(obj.datasets));

  const formatDataSets = (context) => {
    datasets.forEach((ds, i) => {
      const datasetColor = colors[i] || createRandomHex();

      ds.backgroundColor = datasetColor;
      ds.borderColor = datasetColor;
      ds.yAxisID = 'y';
      ds.borderWidth = 2;
      ds.tension = 0.2;
      ds.pointRadius = fullView ? 4 : 0.4;
      ds.fill = true;
      ds.backgroundColor = createGradient(context, datasetColor, fullView);
    });

    return datasets;
  };

  const options = {
    maintainAspectRatio: false,
    layout: {
      padding: {
        top: 15,
      },
    },
    interaction: {
      mode: 'index',
      intersect: false,
    },
    stacked: true,
    plugins: {
      legend: createLegend(fullView),
      tooltip,
    },
    scales: createScales(fullView, false, false),
  };

  return {
    data: (context) => ({
      labels,
      datasets: formatDataSets(context),
    }),
    options,
    className: 'line-chart',
  };
};

export const createDoughnutOptions = (obj, chartColors) => {
  const labels = [...obj.labels];
  const dataset = { ...obj.datasets[0] };
  dataset.backgroundColor = [];
  dataset.borderColor = [];

  for (let i = 0; i < dataset.data.length; i++) {
    dataset.backgroundColor.push((chartColors || colors)[i]);
    dataset.borderColor.push((chartColors || colors)[i]);
  }

  const data = {
    labels,
    datasets: [dataset],
  };

  const options = {
    cutout: 67,
    plugins: {
      legend: {
        display: false,
      },
      tooltip,
    },
  };
  return { data, options, width: '180px', height: '180px' };
};

export const colors = [THEME.blue, THEME.blueDark, THEME.violet, THEME.orange, THEME.green, THEME.greenLight];
