import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTheme } from "styled-components";

import { AuthCard } from "components/auth.layout/pages/login/styled";
import { Button, InputComponent, CardHeading, LightText } from "components/common";
import { loginUser } from "store/reducers/user/actions";

export const Login = () => {
  const { blackPrimary, red } = useTheme();
  const dispatch = useDispatch();
  const { isLoad, error } = useSelector((s) => s.user);
  const [loginData, setLoginData] = useState({ email: "", password: "" });

  const onChange = ({ target: { name, value } }) => setLoginData({ ...loginData, [name]: value });

  const onLogin = () => dispatch(loginUser(loginData));

  return (
    <AuthCard p="30px">
      <CardHeading fs={18} color={blackPrimary} m="0 0 30px">
        Merlin Statistic
      </CardHeading>
      <InputComponent name="email" value={loginData.email} onChange={onChange} margin="0 0 20px" />
      <InputComponent type="password" name="password" value={loginData.password} onChange={onChange} margin={`0 0 ${error ? 10 : 39}px`} />
      {error && (
        <LightText m="0 0 10px" color={red}>
          {error}
        </LightText>
      )}
      <Button is_fill w="100%" h="42px" fs={12} disabled={isLoad} onClick={onLogin}>
        LOGIN
      </Button>
    </AuthCard>
  );
};
