import { createSlice } from '@reduxjs/toolkit';

export const createState = () => ({
  isAppReady: false,
  appError: '',
  walletConnectOptions: [],
  uniqueWalletsList: [],
  marketingCompanies: {},
  subgraphsParams: {
    loaded: false,
    data: { chain: [], protocol: [] },
  },
});

const optionsSlice = createSlice({
  name: 'options',
  initialState: createState(),
  reducers: {
    setAppOptions: (state, { payload: { walletConnectOptions, uniqueWalletsList, marketingCompanies } }) => {
      state.walletConnectOptions = walletConnectOptions;
      state.uniqueWalletsList = uniqueWalletsList;
      state.marketingCompanies = marketingCompanies;
      state.isAppReady = true;
    },
    setSubgraphsParams: (state, { payload: { chain, protocol } }) => {
      state.subgraphsParams.data.chain = chain;
      state.subgraphsParams.data.protocol = protocol;
      state.subgraphsParams.loaded = true;
    },
    setAppError: (state, { payload }) => {
      state.appError = payload;
    },
  },
});

export const { setAppOptions, setAppError, setSubgraphsParams } = optionsSlice.actions;
export default optionsSlice.reducer;
