import { useTheme } from "styled-components";
import { DoughnutChart } from "components/common";
import { withRequest } from "components/hoc/withRequest";

export const VisitorsByDevices = () => {
  const { green, red } = useTheme();

  const Component = withRequest(DoughnutChart, {
    apiMethodName: "getVisitorsByScreenResolution2",
    width: "calc(34% - 15px)",
    chartColors: [green, red],
  });

  return <Component />;
};
