import { useTheme } from 'styled-components';

export const Merlin = ({ color = '' }) => {
  const theme = useTheme();
  return (
    <svg width='130' height='17' viewBox='0 0 130 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M0 17V0H3.73395L11.5703 9.59286H9.21206L17.0485 0H20.7824V17H16.7291V11.4629C16.7291 10.119 16.7619 8.85619 16.8274 7.67429C16.8929 6.49238 17.0239 5.28619 17.2204 4.05571L17.6135 5.41571L11.2019 12.8714H9.58054L3.09525 5.36714L3.562 4.05571C3.75852 5.28619 3.88954 6.49238 3.95504 7.67429C4.02055 8.85619 4.05331 10.119 4.05331 11.4629V17H0Z'
        fill={color || theme.white}
      />
      <path d='M26.7869 17V0H42.2141V3.52143H30.8402V13.4786H42.4597V17H26.7869ZM28.9978 10.03V6.67857H41.1086V10.03H28.9978Z' fill={color || theme.white} />
      <path
        d='M47.7684 17V0H57.742C59.1176 0 60.2886 0.226667 61.2548 0.68C62.2211 1.11714 62.958 1.74857 63.4657 2.57429C63.9734 3.38381 64.2272 4.33905 64.2272 5.44C64.2272 6.52476 63.9407 7.50429 63.3675 8.37857C62.7943 9.23667 62.0163 9.91667 61.0337 10.4186C60.0511 10.9043 58.9539 11.1471 57.742 11.1471H51.748V17H47.7684ZM60.395 17L54.5484 9.32571L58.9211 8.71857L65.259 17H60.395ZM51.748 7.99H57.2506C57.742 7.99 58.1923 7.89286 58.6017 7.69857C59.0275 7.50429 59.3633 7.23714 59.6089 6.89714C59.871 6.54095 60.002 6.1281 60.002 5.65857C60.002 5.18905 59.871 4.79238 59.6089 4.46857C59.3633 4.14476 59.0275 3.89381 58.6017 3.71571C58.1759 3.53762 57.7092 3.44857 57.2015 3.44857H51.748V7.99Z'
        fill={color || theme.white}
      />
      <path d='M70.3091 17V0H74.4853V13.3571H85.5889V17H70.3091Z' fill={color || theme.white} />
      <path d='M90.6543 17V13.3571H95.2727V3.64286H90.6543V0H104.092V3.64286H99.4488V13.3571H104.092V17H90.6543Z' fill={color || theme.white} />
      <path
        d='M110.569 17V0H114.548L127.003 12.1429L126.438 12.0214C126.34 11.4062 126.258 10.8152 126.192 10.2486C126.143 9.6819 126.102 9.13952 126.07 8.62143C126.037 8.10333 126.012 7.60143 125.996 7.11571C125.979 6.63 125.963 6.14429 125.947 5.65857C125.947 5.17286 125.947 4.68714 125.947 4.20143V0H130V17H125.971L113.124 4.46857L114.131 4.63857C114.18 4.88143 114.221 5.15667 114.254 5.46429C114.303 5.77191 114.344 6.12 114.376 6.50857C114.425 6.89714 114.466 7.33429 114.499 7.82C114.548 8.28952 114.581 8.81572 114.597 9.39857C114.614 9.98143 114.622 10.621 114.622 11.3171V17H110.569Z'
        fill={color || theme.white}
      />
    </svg>
  );
};

export const Pie = ({ color = '', size = 16 }) => {
  const theme = useTheme();
  return (
    <svg width={size} height={size} viewBox='0 0 17 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0)'>
        <path
          d='M14.7215 10.7828C14.2974 11.7858 13.634 12.6696 12.7894 13.357C11.9448 14.0444 10.9447 14.5145 9.87644 14.726C8.80822 14.9376 7.70444 14.8843 6.66158 14.5707C5.61873 14.2571 4.66857 13.6929 3.89417 12.9273C3.11976 12.1617 2.5447 11.218 2.21924 10.1788C1.89379 9.1396 1.82786 8.0365 2.02722 6.96593C2.22658 5.89536 2.68515 4.88992 3.36285 4.03752C4.04055 3.18513 4.91674 2.51172 5.91482 2.07617'
          stroke={color || theme.white}
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M15.2482 8.18913C15.2482 7.31365 15.0758 6.44674 14.7407 5.6379C14.4057 4.82907 13.9146 4.09414 13.2956 3.47508C12.6765 2.85603 11.9416 2.36496 11.1328 2.02993C10.3239 1.6949 9.45702 1.52246 8.58154 1.52246V8.18913H15.2482Z'
          stroke={color || theme.white}
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0'>
          <rect width='16' height='16' fill={color || theme.white} transform='translate(0.581543 0.189453)' />
        </clipPath>
      </defs>
    </svg>
  );
};

export const Expand = ({ color = '', size = 14 }) => {
  const { white } = useTheme();
  return (
    <svg width={size} height={size} viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M8.75 1.75H12.25V5.25' stroke={color || white} strokeLinecap='round' strokeLinejoin='round' />
      <path d='M5.25 12.25H1.75V8.75' stroke={color || white} strokeLinecap='round' strokeLinejoin='round' />
      <path d='M12.2501 1.75L8.16675 5.83333' stroke={color || white} strokeLinecap='round' strokeLinejoin='round' />
      <path d='M1.75 12.2503L5.83333 8.16699' stroke={color || white} strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  );
};

export const Info = ({ color = '', size = 36 }) => {
  const { white } = useTheme();
  return (
    <svg width={size} height={size} viewBox='0 0 9 9' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g>
        <path
          d='M4.5 8.25008C6.57107 8.25008 8.25 6.57115 8.25 4.50008C8.25 2.42901 6.57107 0.75008 4.5 0.75008C2.42893 0.75008 0.75 2.42901 0.75 4.50008C0.75 6.57115 2.42893 8.25008 4.5 8.25008Z'
          stroke={color || white}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='0.5'
        />
        <path d='M4.5 6V4.5' stroke={color || white} strokeLinecap='round' strokeLinejoin='round' strokeWidth='0.5' />
        <path d='M4.5 3.00008H4.50375' stroke={color || white} strokeLinecap='round' strokeLinejoin='round' strokeWidth='0.5' />
      </g>
    </svg>
  );
};

export const Trash = ({ color = '', size = 12 }) => {
  const { white } = useTheme();
  return (
    <svg width={size} height={size} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'>
      <path
        fill={color || white}
        d='M135.2 17.69C140.6 6.848 151.7 0 163.8 0H284.2C296.3 0 307.4 6.848 312.8 17.69L320 32H416C433.7 32 448 46.33 448 64C448 81.67 433.7 96 416 96H32C14.33 96 0 81.67 0 64C0 46.33 14.33 32 32 32H128L135.2 17.69zM31.1 128H416V448C416 483.3 387.3 512 352 512H95.1C60.65 512 31.1 483.3 31.1 448V128zM111.1 208V432C111.1 440.8 119.2 448 127.1 448C136.8 448 143.1 440.8 143.1 432V208C143.1 199.2 136.8 192 127.1 192C119.2 192 111.1 199.2 111.1 208zM207.1 208V432C207.1 440.8 215.2 448 223.1 448C232.8 448 240 440.8 240 432V208C240 199.2 232.8 192 223.1 192C215.2 192 207.1 199.2 207.1 208zM304 208V432C304 440.8 311.2 448 320 448C328.8 448 336 440.8 336 432V208C336 199.2 328.8 192 320 192C311.2 192 304 199.2 304 208z'
      />
    </svg>
  );
};

export const Cross = ({ color = '', size = 20 }) => {
  const { red } = useTheme();
  return (
    <svg width={size} height={size} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512'>
      <path
        fill={color || red}
        d='M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z'
      />
    </svg>
  );
};

export const Filter = ({ color = '', size = 16 }) => {
  const { blackPrimary } = useTheme();
  return (
    <svg width={size} height={size} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'>
      <path
        fill={color || blackPrimary}
        d='M3.853 54.87C10.47 40.9 24.54 32 40 32H472C487.5 32 501.5 40.9 508.1 54.87C514.8 68.84 512.7 85.37 502.1 97.33L320 320.9V448C320 460.1 313.2 471.2 302.3 476.6C291.5 482 278.5 480.9 268.8 473.6L204.8 425.6C196.7 419.6 192 410.1 192 400V320.9L9.042 97.33C-.745 85.37-2.765 68.84 3.854 54.87L3.853 54.87z'
      />
    </svg>
  );
};

export const BarChart = ({ color = '', size = 16, isWhite = false }) => {
  const { blackPrimary, white } = useTheme();
  return (
    <svg width={size} height={size} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'>
      <path
        fill={isWhite ? white : color || blackPrimary}
        d='M160 80C160 53.49 181.5 32 208 32H240C266.5 32 288 53.49 288 80V432C288 458.5 266.5 480 240 480H208C181.5 480 160 458.5 160 432V80zM0 272C0 245.5 21.49 224 48 224H80C106.5 224 128 245.5 128 272V432C128 458.5 106.5 480 80 480H48C21.49 480 0 458.5 0 432V272zM400 96C426.5 96 448 117.5 448 144V432C448 458.5 426.5 480 400 480H368C341.5 480 320 458.5 320 432V144C320 117.5 341.5 96 368 96H400z'
      />
    </svg>
  );
};

export const ArrowDown = ({ color }) => {
  const { blackPrimary } = useTheme();

  return (
    <svg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M6 2.5V9.5' stroke={color || blackPrimary} strokeLinecap='round' strokeLinejoin='round' />
      <path d='M9.5 6L6 9.5L2.5 6' stroke={color || blackPrimary} strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  );
};

export const Copy = () => {
  const { blue } = useTheme();

  return (
    <svg width='14' height='14' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_771_101035)'>
        <path
          d='M13.3333 6H7.33333C6.59695 6 6 6.59695 6 7.33333V13.3333C6 14.0697 6.59695 14.6667 7.33333 14.6667H13.3333C14.0697 14.6667 14.6667 14.0697 14.6667 13.3333V7.33333C14.6667 6.59695 14.0697 6 13.3333 6Z'
          stroke={blue}
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M3.33337 9.99992H2.66671C2.31309 9.99992 1.97395 9.85944 1.7239 9.60939C1.47385 9.35935 1.33337 9.02021 1.33337 8.66659V2.66659C1.33337 2.31296 1.47385 1.97382 1.7239 1.72378C1.97395 1.47373 2.31309 1.33325 2.66671 1.33325H8.66671C9.02033 1.33325 9.35947 1.47373 9.60952 1.72378C9.85956 1.97382 10 2.31296 10 2.66659V3.33325'
          stroke={blue}
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_771_101035'>
          <rect width='16' height='16' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};

export const Check = () => {
  const { green } = useTheme();

  return (
    <svg width='14' height='14' viewBox='0 0 7 7' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M5.83317 1.75L2.62484 4.95833L1.1665 3.5' stroke={green} strokeLinecap='round' strokeLinejoin='round' strokeWidth='0.5' />
    </svg>
  );
};

export const ApiIcon = ({ size = 16 }) => {
  const { white } = useTheme();
  return (
    <svg height={size} viewBox='0 0 48 48' width={size} xmlns='http://www.w3.org/2000/svg'>
      <path d='M0 0h48v48h-48z' fill='none' />
      <path
        fill={white}
        d='M26 4.1v6.06c6.78.97 12 6.79 12 13.84 0 1.79-.35 3.5-.96 5.07l5.2 3.07c1.11-2.49 1.76-5.23 1.76-8.14 0-10.37-7.89-18.89-18-19.9zm-2 33.9c-7.73 0-14-6.27-14-14 0-7.05 5.22-12.87 12-13.84v-6.06c-10.12 1-18 9.53-18 19.9 0 11.05 8.94 20 19.99 20 6.62 0 12.47-3.23 16.11-8.18l-5.19-3.06c-2.56 3.19-6.49 5.24-10.91 5.24z'
      />
    </svg>
  );
};

export const GraphIcon = ({ size = 16 }) => {
  const { white } = useTheme();
  return (
    <svg height={size} width={size} viewBox='0 0 256 256' xmlns='http://www.w3.org/2000/svg'>
      <circle cx='128' cy='128' fill='none' r='24' stroke={white} strokeLinecap='round' strokeLinejoin='round' strokeWidth='22' />
      <circle cx='96' cy='56' fill='none' r='24' stroke={white} strokeLinecap='round' strokeLinejoin='round' strokeWidth='22' />
      <circle cx='200' cy='104' fill='none' r='24' stroke={white} strokeLinecap='round' strokeLinejoin='round' strokeWidth='22' />
      <circle cx='200' cy='184' fill='none' r='24' stroke={white} strokeLinecap='round' strokeLinejoin='round' strokeWidth='22' />
      <circle cx='56' cy='192' fill='none' r='24' stroke={white} strokeLinecap='round' strokeLinejoin='round' strokeWidth='22' />
      <line fill='none' stroke={white} strokeLinecap='round' strokeLinejoin='round' strokeWidth='22' x1='118.3' x2='105.7' y1='106.1' y2='77.9' />
      <line fill='none' stroke={white} strokeLinecap='round' strokeLinejoin='round' strokeWidth='22' x1='177.2' x2='150.8' y1='111.6' y2='120.4' />
      <line fill='none' stroke={white} strokeLinecap='round' strokeLinejoin='round' strokeWidth='22' x1='181.1' x2='146.9' y1='169.3' y2='142.7' />
      <line fill='none' stroke={white} strokeLinecap='round' strokeLinejoin='round' strokeWidth='22' x1='110.1' x2='73.9' y1='143.9' y2='176.1' />
    </svg>
  );
};

export const SyncronizeIcon = ({ size = 14.5 }) => {
  return (
    <svg fill='none' height={size} viewBox='0 0 24 24' width={size} xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M3 11.9998C3 7.02919 7.02944 2.99976 12 2.99976C14.8273 2.99976 17.35 4.30342 19 6.34242'
        stroke='blue'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='2'
      />
      <path d='M19.5 2.99976L19.5 6.99976L15.5 6.99976' stroke='blue' strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' />
      <path
        d='M21 11.9998C21 16.9703 16.9706 20.9998 12 20.9998C9.17273 20.9998 6.64996 19.6961 5 17.6571'
        stroke='blue'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='2'
      />
      <path d='M4.5 20.9998L4.5 16.9998L8.5 16.9998' stroke='blue' strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' />
    </svg>
  );
};

export const SyncronizedIcon = ({ size = 14.5 }) => {
  return (
    <svg id='Icons' width={size} height={size} viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'>
      <path fill={'green'} d='M12,0A12,12,0,1,0,24,12,12.013,12.013,0,0,0,12,0Zm0,22A10,10,0,1,1,22,12,10.011,10.011,0,0,1,12,22Z' />
      <path fill={'green'} d='M16.293,8.293,10,14.586,7.707,12.293a1,1,0,0,0-1.414,1.414l3,3a1,1,0,0,0,1.414,0l7-7a1,1,0,0,0-1.414-1.414Z' />
    </svg>
  );
};

export const PausedIcon = ({ size = 14.5 }) => {
  return (
    <svg height={size} version='1.1' viewBox='0 0 25 25' width={size} xmlns='http://www.w3.org/2000/svg'>
      <title />
      <desc />
      <defs />
      <g fill='none' fillRule='evenodd' id='TabBar-Icons' strokeWidth='2'>
        <g fill='orange' id='Pause'>
          <path d='M12.5,25 C19.4035594,25 25,19.4035594 25,12.5 C25,5.59644063 19.4035594,0 12.5,0 C5.59644063,0 0,5.59644063 0,12.5 C0,19.4035594 5.59644063,25 12.5,25 L12.5,25 Z M12.5,24 C18.8512746,24 24,18.8512746 24,12.5 C24,6.14872538 18.8512746,1 12.5,1 C6.14872538,1 1,6.14872538 1,12.5 C1,18.8512746 6.14872538,24 12.5,24 L12.5,24 Z M9,7.99028109 C9,7.44336395 9.44386482,7 10,7 C10.5522847,7 11,7.45126428 11,7.99028109 L11,17.1686545 C11,17.7155716 10.5561352,18.1589355 10,18.1589355 C9.44771525,18.1589355 9,17.7076713 9,17.1686545 L9,7.99028109 L9,7.99028109 Z M14,7.99028109 C14,7.44336395 14.4438648,7 15,7 C15.5522847,7 16,7.45126428 16,7.99028109 L16,17.1686545 C16,17.7155716 15.5561352,18.1589355 15,18.1589355 C14.4477153,18.1589355 14,17.7076713 14,17.1686545 L14,7.99028109 L14,7.99028109 Z' />
        </g>
      </g>
    </svg>
  );
};

export const QuestionIcon = ({ size = 14.5 }) => {
  return (
    <svg fill='none' height={size} strokeWidth='2' viewBox='0 0 24 24' width={size} xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z'
        stroke='gray'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M9 9C9 5.49997 14.5 5.5 14.5 9C14.5 11.5 12 10.9999 12 13.9999' stroke='gray' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M12 18.01L12.01 17.9989' stroke='gray' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  );
};

export const FailedIcon = ({ size = 14.5 }) => {
  return (
    <svg fill='red' version='1.1' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' height={size} width={size}>
      <g style={{ display: 'none' }} id='grid' />
      <g id='icon'>
        <path d='M12,0C5.383,0,0,5.383,0,12s5.383,12,12,12s12-5.383,12-12S18.617,0,12,0z M12,23C5.935,23,1,18.065,1,12S5.935,1,12,1   s11,4.935,11,11S18.065,23,12,23z' />
        <path d='M15.854,8.146c-0.195-0.195-0.512-0.195-0.707,0L12,11.293L8.854,8.146c-0.195-0.195-0.512-0.195-0.707,0   s-0.195,0.512,0,0.707L11.293,12l-3.146,3.146c-0.195,0.195-0.195,0.512,0,0.707C8.244,15.951,8.372,16,8.5,16   s0.256-0.049,0.354-0.146L12,12.707l3.146,3.146C15.244,15.951,15.372,16,15.5,16s0.256-0.049,0.354-0.146   c0.195-0.195,0.195-0.512,0-0.707L12.707,12l3.146-3.146C16.049,8.658,16.049,8.342,15.854,8.146z' />
      </g>
    </svg>
  );
};
