import axios from 'axios';

import { store } from 'store';
import { setExpired } from 'store/reducers/session';

const baseURL = process.env.REACT_APP_SERVER_URL;

const instance = axios.create({ baseURL });
instance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error?.response?.status === 403) {
      const { dispatch, getState } = store;
      const isExpired = getState().session.isExpired;
      if (!isExpired && error.response.request.responseURL !== `${baseURL}/user/login`) {
        dispatch(setExpired(true));
        return;
      }
    }
    return Promise.reject(error);
  }
);

export default class Api {
  //methods

  static axiosGet = (url = '', signal = null, params = {}) =>
    new Promise(async (res, rej) => {
      const response = await instance.get(url, { headers: { Authorization: store.getState().user.token }, signal, params }).catch((e) => rej(e.message));
      if (response?.status === 200) res(await response.data);
    });

  static axiosPost = (url = '', body = {}, signal = null, params = {}) =>
    new Promise(async (res, rej) => {
      const response = await instance.post(url, body, { headers: { Authorization: store.getState().user.token }, signal, params }).catch((e) => rej(e.message));
      if (response?.status === 200) res(await response.data);
    });

  static axiosGetFile = (url = '', body) =>
    new Promise(async (res, rej) => {
      const response = await instance.post(url, body, { headers: { Authorization: store.getState().user.token }, responseType: 'blob' }).catch((e) => rej(e.message));
      if (response?.status === 200) {
        const file = URL.createObjectURL(await response.data);
        const fileName = response.headers['content-disposition'].replace(/(^.+={1,1})|("+)/g, '');
        res({ file, fileName });
      } else rej(null);
    });

  //login in app

  static login = (loginData = {}) => {
    return new Promise(async (res, rej) => {
      const response = await instance.post('/user/login', loginData).catch((e) => rej(e.message));
      if (response?.status === 200) {
        res({ userInfo: response.data, token: response.headers.authorization });
      }
    });
  };

  //check role
  static getRole = () => Api.axiosGet('/whoAmI');

  //app options

  static getWalletConnectOptions = () => Api.axiosGet('/walletConnectOptions');

  static getUniqueWalletsList = () => Api.axiosGet('/getUniqueWallets');

  static getMarketingCompanies = () => Api.axiosGet('/marketingCompanies');

  //all data

  static getNumberOfWallets = (body, signal) => Api.axiosPost('/numberOfWalletsByDay', body, signal);

  static getUniqVisitors = (body, signal) => Api.axiosPost('/numberOfUniqueVisitors', body, signal);

  static getNumberOfSessions = (body, signal) => Api.axiosPost('/numberOfSessions', body, signal);

  static getNumberOfConnectedSessions = (body, signal) => Api.axiosPost('/numberOfSessionsWhereWalletWasConnected', body, signal);

  static getSessionsPerUser = (body, signal) => Api.axiosPost('/averageSessionPerUser', body, signal);

  static getTimePerSession = (body, signal) => Api.axiosPost('/averageTimePerSession', body, signal);

  static getRecencyAndAge = (body, signal) => Api.axiosPost('/recencyAndAge', body, signal);

  static getSummaryInfo = (body, signal) => Api.axiosPost('/getSummuryInfo', body, signal);

  static getAveragePagesPerSessions = (body, signal) => Api.axiosPost('/averagePagesPerSessions', body, signal);

  static getAverageTimePerPage = (body, signal) => Api.axiosPost('/averageTimePerPage', body, signal);

  static getAveragePagesDistribution = (body, signal) => Api.axiosPost('/averagePagesDistribution', body, signal);

  static getAverageTimePerPagesDistribution = (body, signal) => Api.axiosPost('/averageTimePerPagesDistribution', body, signal);

  static getNumberOfWalletsByUser = (body, signal) => Api.axiosPost('/numberOfWalletsByUser', body, signal);

  static getRecencyAndAgeTx = (body, signal) => Api.axiosPost('/recencyAndAgeTx', body, signal);

  static getHoldingsByWallet = (body, signal) => Api.axiosPost('/holdingUsdByWalletDistribution', body, signal);

  static getHoldingsByUser = (body, signal) => Api.axiosPost('/holdingUsdByUserDistribution', body, signal);

  static getNumberOfTxsByWallet = (body, signal) => Api.axiosPost('/numberOfTxsByWallet', body, signal);

  static getNumberOfTxsByUser = (body, signal) => Api.axiosPost('/numberOfTxsByUser', body, signal);

  static getProtocolsByWallet = (body, signal) => Api.axiosPost('/protocolsByWallet', body, signal);

  static getProtocolsByUser = (body, signal) => Api.axiosPost('/protocolsByUser', body, signal);

  static getTokensByValue = (body, signal) => Api.axiosPost('/tokensByValue', body, signal);

  static getWalletsByProvider = (body, signal) => Api.axiosPost('/walletsByProvider', body, signal);

  static getWalletValueByProvider = (body, signal) => Api.axiosPost('/walletValueByProvider', body, signal);

  static getAverageSessionPerUserDistribution = (body, signal) => Api.axiosPost('/averageSessionPerUserDistribution', body, signal);

  static getAverageSessionPerVisitorDistribution = (body, signal) => Api.axiosPost('/averageSessionPerVisitorDistribution', body, signal);

  static getIpsByCountries = (body, signal) => Api.axiosPost('/ipsByCountries', body, signal);

  static getIpsByCountriesV2 = (body, signal) => Api.axiosPost('/ipsByCountriesV2', body, signal);

  static getDeviceIdBySession = (body, count = 10, signal) => Api.axiosPost(`/deviceIdWhereSessionCountWithWalletMoreThan/${count}`, body, signal);

  static getLandingToMerlinFunnel = (body, signal) => Api.axiosPost('/landingToMerlinFunnel', body, signal);

  static getAverageTimePerSessionByDeviceIdDistribution_users = (body, signal) => Api.axiosPost('/averageTimePerSessionByDeviceIdDistribution', body, signal, { type: 'users' });

  static getAverageTimePerSessionByDeviceIdDistribution_visitors = (body, signal) =>
    Api.axiosPost('/averageTimePerSessionByDeviceIdDistribution', body, signal, { type: 'visitors' });

  static getVisitorsByScreenResolution2 = (body, signal) => Api.axiosPost('/visitorsByScreenResolution2', body, signal);

  static getWalletsStatistic = (body, signal) => Api.axiosPost('/summaryWalletsStatistics', body, signal);

  static getPageUsageByType = (body, signal) => Api.axiosPost('/pageUsageByType', body, signal);
  //Api statistic
  static getUsersCountByDay = (body, signal) => Api.axiosPost('/apiusers/usersCountByDay', body, signal);

  static getUsersByUnitsDistribution = (body, signal) => Api.axiosPost('/apiusers/usersByUnitsDistribution', body, signal);

  static getEndpointsCountByDay = (body, signal) => Api.axiosPost('/apiusers/endpointsCountByDay', body, signal);

  static getEndpointsDistribution = (body, signal) => Api.axiosPost('/apiusers/endpointsDistribution', body, signal);

  static getUsersActivityByDayBar = (body, signal) => Api.axiosPost('/apiusers/usersActivityByDayBar', body, signal);

  static getUsersActivityByDayBarCost = (body, signal) => Api.axiosPost('/apiusers/usersActivityByDayBarCost', body, signal);

  static downloadWalletsExcel = (body) => Api.axiosGetFile('/summaryWalletsStatisticsExcel', body);

  static getProTrialSurvey = (body, signal) => Api.axiosPost('/proTrialSurvey', body, signal);
  //Subgraph info
  static getSubgraphsInfo = (params, signal) => Api.axiosGet('/subgraphs-info', signal, params);

  static writeSubgraphComment = (id, comment = '', signal) => Api.axiosPost(`/subgraphs-info/comment/${id}`, { comment }, signal);

  static getPossibleSubgraphsParams = (param, search = null) => Api.axiosGet('/subgraphs-info/values', null, { param, search });
}
