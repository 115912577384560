import { Navigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { Modal } from "components/common";

export const ProtectedRoute = ({ children }) => {
  const dispatch = useDispatch();
  const token = useSelector((s) => s.user.token);
  const isExpired = useSelector((s) => s.session.isExpired);

  const onLogout = () => dispatch({ type: "reset-store" });

  if (!token) return <Navigate to="/" />;

  if (isExpired) return <Modal onSubmit={onLogout} />;

  return children;
};
