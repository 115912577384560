import { Navigate, Link } from 'react-router-dom';

import { statisticPath } from 'App.consts';
import { strategicReportPath } from 'components/data.layout/pages/strategicReport/page.consts';
import { walletsStatisticPath } from 'components/data.layout/pages/walletsStatistic/page.consts';
import { apiStatisticPath } from 'components/data.layout/pages/apiStatistic/page.consts';
import { subgraphsInfoPath } from 'components/data.layout/pages/subgraphsInfo/page.consts';

import { DataApp } from 'data.panel/data.app';
import { StrategicReport } from 'components/data.layout/pages/strategicReport';
import { Tab1, activityPath } from 'components/data.layout/pages/strategicReport/tabs/activity';
import { WalletsStatistic } from 'components/data.layout/pages/walletsStatistic';
import { ApiStatistic } from 'components/data.layout/pages/apiStatistic';
import { SubgraphsInfo } from 'components/data.layout/pages/subgraphsInfo';

import { AuthApp } from 'auth.panel/auth.app';
import { Login } from 'components/auth.layout/pages/login';

export const ROUTES = [
  {
    path: `/${statisticPath}/*`,
    element: <DataApp />,
    children: [
      {
        path: strategicReportPath,
        element: <StrategicReport />,
        children: [
          { path: activityPath, element: <Tab1 /> },
          { path: '', element: <Navigate to={`/${statisticPath}/${strategicReportPath}/${activityPath}`} /> },
        ],
      },
      {
        path: walletsStatisticPath,
        element: <WalletsStatistic />,
      },
      {
        path: apiStatisticPath,
        element: <ApiStatistic />,
      },
      {
        path: subgraphsInfoPath,
        element: <SubgraphsInfo />,
      },
    ],
  },
  { path: '/', element: <AuthApp />, children: [{ path: '', element: <Login /> }] },
  {
    path: '*',
    element: <Link to='/'>Main page</Link>,
  },
];
