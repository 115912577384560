import { DoughnutChart } from "components/common";
import { withRequest } from "components/hoc/withRequest";

export const AverageSessionPerVisitorDistribution = () => {
  const Component = withRequest(DoughnutChart, {
    apiMethodName: "getAverageSessionPerVisitorDistribution",
    width: "calc(34% - 15px)",
  });

  return <Component />;
};
