import { Fragment, useState, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import { useTheme } from 'styled-components';
import { useSelector } from 'react-redux';
import ChartDataLabels from 'chartjs-plugin-datalabels';

import { Card, CardHeading, DarkText, Row, Column, ColorBlock, CrossLine, SkelContent, SkeletonBox, ErrorKpi, Absolute, Button, ModalBg } from 'components/common';
import { Expand } from 'components/icons';
import { createBarOptions, createDatalabels } from 'components/common/barChart/helpers';
import { useToggle } from 'helpers/hooks';
import Api from 'api';

export const BarChart = ({ apiMethodName = 'getTokensByValue', width = 'calc(50% - 15px)', withLabels = false }) => {
  const filters = useSelector((s) => s.filters);
  const [
    {
      data: { title, plots },
      load,
      error,
    },
    setKpi,
  ] = useState({ load: true, error: '', data: { title: '', plots: null } });

  useEffect(() => {
    const controller = new AbortController();

    setKpi((prev) => ({ ...prev, load: true, error: '' }));
    Api[apiMethodName](filters, controller.signal)
      .then((data) => setKpi((prev) => ({ ...prev, data, load: false, error: '' })))
      .catch((error) => {
        if (error !== 'canceled') {
          setKpi((prev) => ({ ...prev, load: false, error }));
        }
      });

    return () => controller.abort();
  }, [filters, apiMethodName]);

  const [fullView, setFull] = useToggle();

  const { blue, greenLight, red, gold, violet, green, orange, violetLight, header, blueDark } = useTheme();
  const defaultColors = [blue, greenLight, red, gold, violet, green, orange, violetLight, header, blueDark];

  if (load) return <BarSkeleton {...{ width }} />;

  if (error) return <ErrorKpi width={width} message={error} />;

  return (
    <Fragment>
      <Card h='522px' visible m='0 0 30px' w={width} p='16px 20px 24px'>
        <Row>
          <CardHeading>{title}</CardHeading>
        </Row>
        <CrossLine parentPadding={20} />
        <Row m='5px auto 0' position='relative' h='350px' w='100%'>
          <Bar {...createBarOptions(plots.Single, false, defaultColors, withLabels ? createDatalabels() : {})} plugins={withLabels ? [ChartDataLabels] : []} />
          <Absolute>
            <Button no_text is_fill w='24px' z={3} h='24px' onClick={setFull}>
              <Expand />
            </Button>
          </Absolute>
        </Row>
        <Row hidden isWrap m='8px 0 0'>
          {[...plots.Single.labels].slice(0, 10).map((el, index) => (
            <Row
              w={plots.Single.labels.length > 10 ? 'calc(20% - 10px)' : `calc(${100 / Math.ceil(plots.Single.labels.length / 2)}% - 10px)`}
              m='16px 0 0'
              align='center'
              justify='flex-start'
              key={el + index}
            >
              <ColorBlock bg={defaultColors[index]} />
              <Column w='calc(100% - 37px)'>
                <DarkText fs={12}>{el}</DarkText>
              </Column>
            </Row>
          ))}
          <Row w={plots.Single.labels.length > 10 ? 'calc(20% - 10px)' : `calc(${100 / Math.ceil(plots.Single.labels.length / 2)}% - 10px)`} />
          <Row w={plots.Single.labels.length > 10 ? 'calc(20% - 10px)' : `calc(${100 / Math.ceil(plots.Single.labels.length / 2)}% - 10px)`} />
          <Row w={plots.Single.labels.length > 10 ? 'calc(20% - 10px)' : `calc(${100 / Math.ceil(plots.Single.labels.length / 2)}% - 10px)`} />
          <Row w={plots.Single.labels.length > 10 ? 'calc(20% - 10px)' : `calc(${100 / Math.ceil(plots.Single.labels.length / 2)}% - 10px)`} />
        </Row>
      </Card>
      {fullView && (
        <ModalBg>
          <Card h='100%' p='30px'>
            <Row m='5px auto 0' position='relative' h='100%' w='100%'>
              <Bar {...createBarOptions(plots.Single, true, defaultColors, withLabels ? createDatalabels(16) : {})} plugins={withLabels ? [ChartDataLabels] : []} />
              <Absolute>
                <Button no_text is_fill w='24px' z={3} h='24px' onClick={setFull}>
                  <Expand />
                </Button>
              </Absolute>
            </Row>
          </Card>
        </ModalBg>
      )}
    </Fragment>
  );
};

export const BarSkeleton = ({ width }) => (
  <SkeletonBox w={width} h='522px' m='0 0 30px' p='16px 20px 24px'>
    <SkelContent h='18px' m='0 0 31px' />
    <Row m='5px auto 0' position='relative' h='350px' w='100%' align='flex-end' p='0 5% 10px'>
      <SkelContent h={`${Math.round(Math.random() * 100)}%`} w='7%' />
      <SkelContent h={`${Math.round(Math.random() * 100)}%`} w='7%' />
      <SkelContent h={`${Math.round(Math.random() * 100)}%`} w='7%' />
      <SkelContent h={`${Math.round(Math.random() * 100)}%`} w='7%' />
      <SkelContent h={`${Math.round(Math.random() * 100)}%`} w='7%' />
      <SkelContent h={`${Math.round(Math.random() * 100)}%`} w='7%' />
      <SkelContent h={`${Math.round(Math.random() * 100)}%`} w='7%' />
      <SkelContent h={`${Math.round(Math.random() * 100)}%`} w='7%' />
      <SkelContent h={`${Math.round(Math.random() * 100)}%`} w='7%' />
      <SkelContent h={`${Math.round(Math.random() * 100)}%`} w='7%' />
    </Row>
    <Row h='70px' w='100%' isWrap m='30px 0 0'>
      <SkelContent h='19px' w='calc(20% - 10px)' />
      <SkelContent h='19px' w='calc(20% - 10px)' />
      <SkelContent h='19px' w='calc(20% - 10px)' />
      <SkelContent h='19px' w='calc(20% - 10px)' />
      <SkelContent h='19px' w='calc(20% - 10px)' />
      <SkelContent h='19px' w='calc(20% - 10px)' />
      <SkelContent h='19px' w='calc(20% - 10px)' />
      <SkelContent h='19px' w='calc(20% - 10px)' />
      <SkelContent h='19px' w='calc(20% - 10px)' />
      <SkelContent h='19px' w='calc(20% - 10px)' />
    </Row>
  </SkeletonBox>
);
