import { Fragment } from 'react';
import { useTheme } from 'styled-components';
import { useSelector } from 'react-redux';

import { Row, HeaderCard, CardHeading, LineChart, BarChart, GeoChart } from 'components/common';
import { RecencyAndAge } from 'components/data.layout/pages/strategicReport/tabs/activity/RecencyAngAge';
import { Summary } from 'components/data.layout/pages/strategicReport/tabs/activity/Summary';
import { PagesAverage } from 'components/data.layout/pages/strategicReport/tabs/activity/PagesAverage';
import { NumberOfWalletsByUser } from 'components/data.layout/pages/strategicReport/tabs/activity/NumberOfWalletsByUser';
import { Holdings } from 'components/data.layout/pages/strategicReport/tabs/activity/Holdings';
import { Transactions } from 'components/data.layout/pages/strategicReport/tabs/activity/Transactions';
import { Protocols } from 'components/data.layout/pages/strategicReport/tabs/activity/Protocols';
import { AverageSessionPerUserDistribution } from 'components/data.layout/pages/strategicReport/tabs/activity/AvgSessionPerUserDist';
import { AverageSessionPerVisitorDistribution } from 'components/data.layout/pages/strategicReport/tabs/activity/AvgSessionPerVisitorDist';
import { VisitorsByDevices } from 'components/data.layout/pages/strategicReport/tabs/activity/VisitorsByDevices';
import { PageUsageByType } from 'components/data.layout/pages/strategicReport/tabs/activity/PageUsageByType';

import { activityChildrenAdmin, activityChildrenMarketing } from 'components/data.layout/pages/strategicReport/page.consts';

export const activityPath = 'activity';

export const Tab1 = () => {
  const role = useSelector((s) => s.user.role);
  const { gold, violet, orange, blueDark, red, green, greenLight, violetLight } = useTheme();
  return role === 'MARKETING_AGENCY' ? (
    <Fragment key='MARKETING_AGENCY'>
      <HeaderCard h='60px' m='0 0 30px'>
        <CardHeading m='auto 30px' id={activityChildrenMarketing[0].id}>
          {activityChildrenMarketing[0].text}
        </CardHeading>
      </HeaderCard>
      <Summary />
      <GeoChart apiMethodName='getIpsByCountriesV2' />
      <HeaderCard h='60px' m='0 0 30px'>
        <CardHeading m='auto 30px' id={activityChildrenMarketing[1].id}>
          {activityChildrenMarketing[1].text}
        </CardHeading>
      </HeaderCard>
      <Row isWrap>
        <LineChart
          {...{
            width: 'calc(66% - 15px)',
            apiMethodName: 'getNumberOfWallets',
            chartColors: [violet, orange, blueDark, red, green, greenLight],
          }}
        />
        <NumberOfWalletsByUser {...{ width: 'calc(34% - 15px)' }} />
        <LineChart {...{ width: '100%', apiMethodName: 'getUniqVisitors', chartColors: [blueDark, gold, orange, red, green, violet], height: '437px' }} />
      </Row>
      <HeaderCard h='60px' m='0 0 30px'>
        <CardHeading m='auto 30px' id={activityChildrenMarketing[2].id}>
          {activityChildrenMarketing[2].text}
        </CardHeading>
      </HeaderCard>
      <Row>
        <LineChart {...{ width: 'calc(66% - 15px)', apiMethodName: 'getNumberOfSessions', chartColors: [red, orange, blueDark, green, greenLight, violetLight] }} />
        <AverageSessionPerUserDistribution />
      </Row>
      <Row>
        <LineChart {...{ width: 'calc(66% - 15px)', apiMethodName: 'getNumberOfConnectedSessions' }} />
        <AverageSessionPerVisitorDistribution />
      </Row>
      <Row>
        <LineChart {...{ width: 'calc(50% - 15px)', apiMethodName: 'getSessionsPerUser', chartColors: [orange, blueDark, red, green, greenLight, violetLight] }} />
        <LineChart {...{ width: 'calc(50% - 15px)', apiMethodName: 'getTimePerSession', chartColors: [gold, orange, blueDark, red, green, greenLight, violetLight] }} />
      </Row>
      <Row>
        <BarChart apiMethodName='getAverageTimePerSessionByDeviceIdDistribution_users' width='calc(50% - 15px)' />
        <BarChart apiMethodName='getAverageTimePerSessionByDeviceIdDistribution_visitors' width='calc(50% - 15px)' />
      </Row>
      <HeaderCard h='60px' m='0 0 30px'>
        <CardHeading m='auto 30px' id={activityChildrenMarketing[3].id}>
          {activityChildrenMarketing[3].text}
        </CardHeading>
      </HeaderCard>
      <Row>
        <BarChart apiMethodName='getTokensByValue' />
        <BarChart apiMethodName='getWalletsByProvider' />
      </Row>
      <Row>
        <BarChart apiMethodName='getWalletValueByProvider' />
        <BarChart apiMethodName='getIpsByCountries' />
      </Row>
      <HeaderCard h='60px' m='0 0 30px'>
        <CardHeading m='auto 30px' id={activityChildrenMarketing[4].id}>
          {activityChildrenMarketing[4].text}
        </CardHeading>
      </HeaderCard>
      <Row>
        <LineChart
          {...{
            width: 'calc(66% - 15px)',
            apiMethodName: 'getLandingToMerlinFunnel',
            chartColors: [blueDark, gold, orange, red, green, greenLight, violetLight],
          }}
        />
        <VisitorsByDevices />
      </Row>
    </Fragment>
  ) : (
    <Fragment key='ADMIN'>
      <HeaderCard h='60px' m='0 0 30px'>
        <CardHeading m='auto 30px' id={activityChildrenAdmin[0].id}>
          {activityChildrenAdmin[0].text}
        </CardHeading>
      </HeaderCard>
      <Summary />
      <GeoChart apiMethodName='getIpsByCountriesV2' />
      <HeaderCard h='60px' m='0 0 30px'>
        <CardHeading m='auto 30px' id={activityChildrenAdmin[1].id}>
          {activityChildrenAdmin[1].text}
        </CardHeading>
      </HeaderCard>
      <Row isWrap>
        <LineChart
          {...{
            width: 'calc(66% - 15px)',
            apiMethodName: 'getNumberOfWallets',
            chartColors: [violet, orange, blueDark, red, green, greenLight],
          }}
        />
        <NumberOfWalletsByUser {...{ width: 'calc(34% - 15px)' }} />
        <LineChart {...{ width: '100%', apiMethodName: 'getUniqVisitors', chartColors: [blueDark, gold, orange, red, green, violet], height: '437px' }} />
      </Row>
      <HeaderCard h='60px' m='0 0 30px'>
        <CardHeading m='auto 30px' id={activityChildrenAdmin[2].id}>
          {activityChildrenAdmin[2].text}
        </CardHeading>
      </HeaderCard>
      <Row>
        <LineChart {...{ width: 'calc(66% - 15px)', apiMethodName: 'getNumberOfSessions', chartColors: [red, orange, blueDark, green, greenLight, violetLight] }} />
        <AverageSessionPerUserDistribution />
      </Row>
      <Row>
        <LineChart {...{ width: 'calc(66% - 15px)', apiMethodName: 'getNumberOfConnectedSessions' }} />
        <AverageSessionPerVisitorDistribution />
      </Row>
      <Row>
        <LineChart {...{ width: 'calc(50% - 15px)', apiMethodName: 'getSessionsPerUser', chartColors: [orange, blueDark, red, green, greenLight, violetLight] }} />
        <LineChart {...{ width: 'calc(50% - 15px)', apiMethodName: 'getTimePerSession', chartColors: [gold, orange, blueDark, red, green, greenLight, violetLight] }} />
      </Row>
      <Row>
        <BarChart apiMethodName='getAverageTimePerSessionByDeviceIdDistribution_users' width='calc(50% - 15px)' withLabels />
        <BarChart apiMethodName='getAverageTimePerSessionByDeviceIdDistribution_visitors' width='calc(50% - 15px)' />
      </Row>
      <HeaderCard h='60px' m='0 0 30px'>
        <CardHeading m='auto 30px' id={activityChildrenAdmin[3].id}>
          {activityChildrenAdmin[3].text}
        </CardHeading>
      </HeaderCard>
      <RecencyAndAge />
      <HeaderCard h='60px' m='0 0 30px'>
        <CardHeading m='auto 30px' id={activityChildrenAdmin[4].id}>
          {activityChildrenAdmin[4].text}
        </CardHeading>
      </HeaderCard>
      <Row>
        <LineChart {...{ width: 'calc(50% - 15px)', apiMethodName: 'getAveragePagesPerSessions', chartColors: [blueDark, orange, red, green, greenLight, violetLight] }} />
        <LineChart {...{ width: 'calc(50% - 15px)', apiMethodName: 'getAverageTimePerPage', chartColors: [blueDark, orange, red, green, greenLight, violetLight] }} />
      </Row>
      <PagesAverage />
      <HeaderCard h='60px' m='0 0 30px'>
        <CardHeading m='auto 30px' id={activityChildrenAdmin[5].id}>
          {activityChildrenAdmin[5].text}
        </CardHeading>
      </HeaderCard>
      <RecencyAndAge apiMethodName='getRecencyAndAgeTx' />
      <HeaderCard h='60px' m='0 0 30px'>
        <CardHeading m='auto 30px' id={activityChildrenAdmin[6].id}>
          {activityChildrenAdmin[6].text}
        </CardHeading>
      </HeaderCard>
      <Holdings />
      <HeaderCard h='60px' m='0 0 30px'>
        <CardHeading m='auto 30px' id={activityChildrenAdmin[7].id}>
          {activityChildrenAdmin[7].text}
        </CardHeading>
      </HeaderCard>
      <Transactions />
      <HeaderCard h='60px' m='0 0 30px'>
        <CardHeading m='auto 30px' id={activityChildrenAdmin[8].id}>
          {activityChildrenAdmin[8].text}
        </CardHeading>
      </HeaderCard>
      <Protocols />
      <HeaderCard h='60px' m='0 0 30px'>
        <CardHeading m='auto 30px' id={activityChildrenAdmin[9].id}>
          {activityChildrenAdmin[9].text}
        </CardHeading>
      </HeaderCard>
      <Row>
        <BarChart apiMethodName='getTokensByValue' />
        <BarChart apiMethodName='getWalletsByProvider' />
      </Row>
      <Row>
        <BarChart apiMethodName='getWalletValueByProvider' />
        <BarChart apiMethodName='getIpsByCountries' />
      </Row>
      <HeaderCard h='60px' m='0 0 30px'>
        <CardHeading m='auto 30px' id={activityChildrenAdmin[10].id}>
          {activityChildrenAdmin[10].text}
        </CardHeading>
      </HeaderCard>
      <Row>
        <LineChart
          {...{
            width: 'calc(66% - 15px)',
            apiMethodName: 'getLandingToMerlinFunnel',
            chartColors: [blueDark, gold, orange, red, green, greenLight, violetLight],
          }}
        />
        <VisitorsByDevices />
      </Row>
      <HeaderCard h='60px' m='0 0 30px'>
        <CardHeading m='auto 30px' id={activityChildrenAdmin[11].id}>
          {activityChildrenAdmin[11].text}
        </CardHeading>
      </HeaderCard>
      <PageUsageByType />
      <HeaderCard h='60px' m='0 0 30px'>
        <CardHeading m='auto 30px' id={activityChildrenAdmin[12].id}>
          {activityChildrenAdmin[12].text}
        </CardHeading>
      </HeaderCard>
      <LineChart {...{ width: '100%', apiMethodName: 'getProTrialSurvey' }} />
    </Fragment>
  );
};
