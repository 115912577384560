import { createSlice } from "@reduxjs/toolkit";

export const createState = () => ({
  isExpired: false,
});

const sessionSlice = createSlice({
  name: "session",
  initialState: createState(),
  reducers: {
    setExpired: (state, { payload }) => {
      state.isExpired = payload;
    },
  },
});

export const { setExpired } = sessionSlice.actions;
export default sessionSlice.reducer;
