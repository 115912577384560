import { useMemo } from "react";
import { Doughnut } from "react-chartjs-2";

import { Card, CardHeading, DarkText, LightText, Row, Column, ColorBlock, CrossLine, CenterInfo, EmptyDoughnut } from "components/common";
import { colors, createDoughnutOptions } from "components/common/lineChart/helpers";

export const DoughnutChart = ({ width = "calc(50% - 15px)", kpi: { title, plots }, chartProps = {}, chartColors }) => {
  const total = plots.Total.datasets[0].data.reduce((a, b) => a + b, 0);
  const [[heading, value]] = Object.entries(plots.Total.summary);
  const config = useMemo(() => createDoughnutOptions(plots.Total, chartColors), [plots, chartColors]);
  return (
    <Card h="390px" visible m="0 0 30px" w={width} p="16px 20px">
      <Row>
        <CardHeading>{title}</CardHeading>
      </Row>
      <CrossLine parentPadding={20} />
      <Column m="5px auto 0">
        {config.data.datasets[0].data.some((v) => v) ? <Doughnut {...config} {...chartProps} /> : <EmptyDoughnut />}
        <CenterInfo>
          <LightText align="center">{heading}</LightText>
          <DarkText isWrap align="center">
            {value}
          </DarkText>
        </CenterInfo>
      </Column>
      <Row hidden isWrap m="8px 0 0">
        {plots.Total.datasets[0].data.map((value, index) => (
          <Row w="calc(50% - 10px)" m="16px 0 0" justify="flex-start" key={`${value}-${index}`}>
            <ColorBlock bg={(chartColors || colors)[index]} />
            <Column w="calc(100% - 37px)">
              <DarkText m="0 0 2px" fs={12}>
                {plots.Total.labels[index]}
              </DarkText>
              <LightText fw={500} fs={12}>
                {value} ({!isNaN(value / total) ? ((value / total) * 100).toFixed(2) : 0}%)
              </LightText>
            </Column>
          </Row>
        ))}
      </Row>
    </Card>
  );
};
