import { css } from "styled-components";

export const border4 = css`
  border-radius: 4px;
`;

export const fullVwVh = css`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
`;

export const transition = css`
  transition: all 100ms ease-in-out;
`;

export const textToDot = css`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const flexCenter = css`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const noUserSelect = css`
  user-select: none;
  * {
    user-select: none;
  }
`;
