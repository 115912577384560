import styled, { css } from "styled-components";
import { textToDot } from "components/common/mixins";

export const textStyles = css`
  font-size: ${({ fs = 14 }) => `${fs}px`};
  line-height: 135%;
  color: ${({ theme: { textPrimary } }) => textPrimary};
  margin: ${({ m = 0 }) => m};
  font-weight: ${({ fw = 600 }) => fw};
  text-align: ${({ align = "left" }) => align};
  text-transform: ${({ uppercase }) => (uppercase ? "uppercase" : "none")};
`;

export const CardHeading = styled.h3`
  color: ${({ theme: { greyTert }, color }) => color || greyTert};
  ${textToDot}
  ${textStyles}
`;

export const DarkText = styled(CardHeading)`
  color: ${({ theme: { blackTert } }) => blackTert};
  ${({ isWrap = false }) => isWrap && `white-space: normal;`}
`;

export const LightText = styled.p`
  ${textStyles}
  ${textToDot}
  color: ${({ theme: { greyMiddle }, color }) => color || greyMiddle};
  ${({ isWrap = false }) => isWrap && `white-space: normal;`}
`;
