import { DoughnutChart, Row } from 'components/common';
import { withRequest } from 'components/hoc/withRequest';

export const Protocols = () => {
  const ProtocolsByWallet = withRequest(DoughnutChart, { apiMethodName: 'getProtocolsByWallet', width: 'calc(50% - 15px)' });

  const ProtocolsByUser = withRequest(DoughnutChart, { apiMethodName: 'getProtocolsByUser', width: 'calc(50% - 15px)' });

  return (
    <Row>
      <ProtocolsByWallet />
      <ProtocolsByUser />
    </Row>
  );
};
