export const tooltip = {
  titleFont: {
    family: 'Montserrat',
  },
  titleColor: '#181818',
  bodyColor: '#181818',
  borderColor: '#EFEFEF',
  borderWidth: 1,
  bodyFont: {
    family: 'Montserrat',
    color: '#181818',
  },

  bodySpacing: 12,
  titleMarginBottom: 12,
  cornerRadius: 4,
  backgroundColor: '#fff',
  boxWidth: 22,
  boxHeight: 12,
};

export const createLegend = (fullView) => ({
  display: fullView,
  labels: {
    font: {
      family: 'Montserrat',
      weight: 500,
    },
  },
});

export const createScales = (fullView, stackedX = false, stackedY = false, secondY = false) => {
  const scales = {
    y: {
      stacked: stackedY,
      grid: {
        display: fullView,
        drawBorder: fullView,
      },
      ticks: {
        display: fullView,
        font: {
          family: 'Montserrat',
        },
      },
      beginAtZero: true,
    },
    x: {
      stacked: stackedX,
      grid: {
        display: fullView,
        drawBorder: fullView,
      },
      ticks: {
        display: fullView,
        font: {
          family: 'Montserrat',
        },
      },
    },
  };
  if (secondY) {
    scales.y1 = {
      stacked: stackedY,
      grid: {
        display: fullView,
        drawBorder: fullView,
      },
      ticks: {
        display: fullView,
        font: {
          family: 'Montserrat',
        },
      },
    };
  }
  return scales;
};

export const createGradient = (canvas, color, fullView) => () => {
  const ctx = canvas.getContext('2d');
  const gradient = ctx.createLinearGradient(0, 0, 0, fullView ? window.innerHeight - 160 : 220);
  gradient.addColorStop(0, color + '48');
  gradient.addColorStop(1, color + '00');

  return gradient;
};

export const createRandomHex = () => '#' + ((Math.random() * 0xffffff) << 0).toString(16).padStart(6, '0');

export function splitWalletAddress(address) {
  return address.slice(0, 6) + '...' + address.slice(-6);
}

export function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
