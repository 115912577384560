import React, { Fragment, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import { useTheme } from 'styled-components';

import { SelectBlock, Options, Option, SelectColumn, Input } from 'components/common/select/styled';
import { LayoutTransp, DarkText, CounterLabel, Row, Button, Absolute } from 'components/common';
import { Trash, Cross } from 'components/icons';

import { useToggle } from 'helpers/hooks';

export const Select = ({ hashMap, value, onChange = console.log }) => {
  const [open, setOpen] = useToggle();

  const _onChange = (val) => () => {
    onChange(val);
    setOpen();
  };

  const getPlaceHolder = () => {
    if ((value || value === 0) && hashMap) {
      for (const key in hashMap) {
        if (hashMap[key] === value) {
          return key;
        }
      }
    }
    return '';
  };

  const renderOptions = () => {
    const arr = Object.entries(hashMap);
    return arr.map(([key, value], i) => (
      <Option key={`${key}-${value}-${i}`} onClick={_onChange(value)} align='center' p='0 12px'>
        <DarkText fw={400} fs={12}>
          {key}
        </DarkText>
      </Option>
    ));
  };

  return (
    <Fragment>
      <SelectColumn>
        <SelectBlock is_open={open} align='center' fw={400} fs={12} value={value} onClick={setOpen}>
          <span>{getPlaceHolder()}</span>
        </SelectBlock>
        <Options is_open={open} w='100%'>
          {renderOptions()}
        </Options>
      </SelectColumn>
      {open ? <LayoutTransp transparent onClick={setOpen} /> : null}
    </Fragment>
  );
};

export const SelectMultiple = ({ values = [], defaultValue = [], onChange = console.log, placeholder, emptyPlaceholder = 'Select from list', ordinaryWidth = false }) => {
  const [open, setOpen] = useToggle();
  const [keywords, setKeyWords] = useState('');
  const [value, setValue] = useState(defaultValue);
  const { white, greyTert } = useTheme();

  const _onChange = (el) => () => {
    const _value = [...value];
    const index = _value.indexOf(el);
    if (index !== -1) _value.splice(index, 1);
    else _value.push(el);
    setValue(_value);
    onChange(_value);
  };

  const onChangeKeywords = (e) => setKeyWords(e.target.value);

  const onClearValue = () => {
    setValue([]);
    onChange([]);
  };

  const onClearKeywords = () => setKeyWords('');

  const renderOptions = () =>
    values
      .filter((el) => el.toLowerCase().includes(keywords.toLowerCase()))
      .map((el, i) => (
        <Option isActive={value.includes(el)} key={`${el}-${i}`} onClick={_onChange(el)} align='center' p='0 12px'>
          <DarkText fw={400} fs={12}>
            {el}
          </DarkText>
        </Option>
      ));

  return (
    <Fragment>
      <SelectColumn>
        <SelectBlock is_open={open} align='center' fw={400} fs={12} onClick={setOpen}>
          <span>{value.length ? placeholder : emptyPlaceholder}</span>
          {value.length ? (
            <CounterLabel w='16px' h='16px' align='center' justify='center'>
              {value.length}
            </CounterLabel>
          ) : null}
        </SelectBlock>
        <Options is_open={open} w={ordinaryWidth ? '100%' : '333px'}>
          <Row p='12px' align='center'>
            <Row w={value.length ? 'calc(100%  - 36px)' : '100%'} position='relative'>
              <Input placeholder='Search...' fw={400} fs={12} value={keywords} onChange={onChangeKeywords} />
              {keywords ? (
                <Absolute top='7px' pointer data-tip data-for='clear-search' onClick={onClearKeywords}>
                  <Cross />
                  <ReactTooltip id='clear-search' backgroundColor={white} arrowColor={white} borderColor={greyTert} border={true} place='top' effect='solid'>
                    <DarkText fw={400} fs={12}>
                      Clear Search
                    </DarkText>
                  </ReactTooltip>
                </Absolute>
              ) : null}
            </Row>
            {value.length ? (
              <Fragment>
                <Button is_fill data-tip data-for='clear-value' no_text w='24px' z={3} h='24px' onClick={onClearValue}>
                  <Trash />
                </Button>
                <ReactTooltip id='clear-value' backgroundColor={white} arrowColor={white} borderColor={greyTert} border={true} place='top' effect='solid'>
                  <DarkText fw={400} fs={12}>
                    Clear List
                  </DarkText>
                </ReactTooltip>
              </Fragment>
            ) : null}
          </Row>
          <div style={{ maxHeight: 238, overflow: 'auto' }}>{renderOptions()}</div>
        </Options>
      </SelectColumn>
      {open ? <LayoutTransp transparent onClick={setOpen} /> : null}
    </Fragment>
  );
};
