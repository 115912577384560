import { createGlobalStyle } from 'styled-components';

import { border4 } from 'components/common/mixins';

export const THEME = {
  body: '#FBFBFF',
  header: '#1F263E',
  white: '#FFFFFF',
  blackPrimary: '#333333',
  blackSecondary: '#181818',
  blackTert: '#494D57',
  greyLight: '#B2B6C1',
  greyMiddle: '#A0A0A0',
  greyTert: '#696C76',
  greyBorder: '#EFEFEF',
  greyCard: '#EEF2F7',
  blue: '#6280DA',
  blueDark: '#304da4',
  green: '#68D4AD',
  greenLight: '#80F0C8',
  red: '#EF5656',
  gold: '#FFCC01',
  orange: '#FF9901',
  violet: '#AD87FF',
  violetLight: '#DAC9FC',
};
//  #3D61CE
export const GlobalStyles = createGlobalStyle`
* {
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
}
body {
  background-color: ${({ theme: { body } }) => body};
  margin: 0;
  padding: 0;
}
a {
  text-decoration:none
}
canvas {
  position: relative;
  z-index: 2;
}
.line-chart,
.bar-line-chart,
.geo-chart {  
  ${border4}
  background: ${({ theme: { body } }) => body}; 
  border: 1px solid ${({ theme: { greyBorder } }) => greyBorder};
  overflow:hidden;
}
`;
