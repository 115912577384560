import { useTheme } from "styled-components";

import { Card, Row, CardHeading, CrossLine, LightText } from "components/common";

export const ErrorKpi = ({ width = "100%", message = "" }) => {
  const { red } = useTheme();
  return (
    <Card h="390px" visible m="0 0 30px" w={width} p="16px 20px">
      <Row>
        <CardHeading color={red}>Something went wrong</CardHeading>
      </Row>
      <CrossLine parentPadding={20} />
      <Card h="242px" w="100%" bg={red + "16"} m="5px auto 0">
        <Row align="center" justify="center" h="100%">
          <LightText color={red}>{message}</LightText>
        </Row>
      </Card>
    </Card>
  );
};
