import { BarChart } from 'components/icons';
import { statisticPath } from 'App.consts';

import { activityPath } from 'components/data.layout/pages/strategicReport/tabs/activity';

export const strategicReportPath = 'report';

export const activityChildrenAdmin = [
  { text: 'Geography and Summary', id: 'summary' },
  { text: 'Wallets and Visitors', id: 'wallets-visitors' },
  { text: 'Sessions', id: 'sessions' },
  { text: 'Recency And Age', id: 'recency-age' },
  { text: 'Pages activity', id: 'pages-activity' },
  { text: 'Recency And Age Tx', id: 'recency-age-tx' },
  { text: 'Holdings', id: 'holdings' },
  { text: 'Transactions', id: 'transactions' },
  { text: 'Protocols', id: 'protocols' },
  { text: 'Tokens / Providers', id: 'tokens-providers' },
  { text: 'Landing To Funnel', id: 'landing-to-funnel' },
  { text: 'Page Usage By Type', id: 'page-usage-by-type' },
  { text: 'Pro Trial Survey', id: 'pro-trial-survey' },
];

export const activityChildrenMarketing = [
  { text: 'Geography and Summary', id: 'summary' },
  { text: 'Wallets and Visitors', id: 'wallets-visitors' },
  { text: 'Sessions', id: 'sessions' },
  { text: 'Tokens / Providers', id: 'tokens-providers' },
  { text: 'Landing To Funnel', id: 'landing-to-funnel' },
];

export const createNavigation = (iconColor, isMarketingRole = false) => [
  {
    icon: <BarChart color={iconColor} />,
    text: 'Activity',
    to: `/${statisticPath}/${strategicReportPath}/${activityPath}`,
    children: isMarketingRole ? activityChildrenMarketing : activityChildrenAdmin,
  },
];

export const periodOptionsHashMap = {
  Total: 2147483647,
  'Last Year': 365,
  'Last 3 Months': 90,
  'Last Month': 30,
  'Last Week': 7,
  'Day Before': 1,
};

export const chronoUnitsHashMap = { Days: 'DAYS', Hours: 'HOURS' };

export const walletsListHashMap = { All: 'ALL', 'Black List': 'BLACK_LIST', 'White List': 'WHITE_LIST' };

export const walletsListEnum = { ALL: 'All', BLACK_LIST: 'Black List', WHITE_LIST: 'White List' };

export const platformsHashMap = {
  All: 'ALL',
  Mobile: 'MOBILE',
  Desktop: 'DESKTOP',
};
