import styled from "styled-components";

export const VerticalLine = styled.div`
  height: ${({ parentPadding = 25 }) => `calc(100% + ${parentPadding * 2}px)`};
  top: ${({ parentPadding = 25 }) => `-${parentPadding}px`};
  border-left: 1px solid ${({ theme: { white }, color }) => color || white + "12"};
  width: 0;
  margin: ${({ m = 0 }) => `0 ${m}`};
`;

export const CrossLine = styled.div`
  border-top: 1px solid ${({ theme: { greyBorder } }) => greyBorder};
  height: 0;
  position: relative;
  width: ${({ parentPadding = 25 }) => `calc(100% + ${parentPadding * 2}px)`};
  left: ${({ parentPadding = 25 }) => `-${parentPadding}px`};
  margin: ${({ m, parentPadding = 25 }) => (m ? m : `${parentPadding - 5}px 0`)};
`;
