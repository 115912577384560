import styled from 'styled-components';

import { Column, flexCenter } from 'components/common';

export const Wrapper = styled(Column)`
  min-height: calc(100vh - 60px);
`;

export const Header = styled.header`
  position: relative;
  background-color: ${({ theme: { header } }) => header};
  height: 60px;
  padding: 0 30px;
  display: flex;
  align-items: center;
  > h6 {
    margin: 0;
    color: ${({ theme: { white } }) => white};
    font-size: 10px;
    line-height: 155%;
  }
`;

export const VerticalBorder = styled.div`
  width: 2px;
  height: 24px;
  margin: 0 16px;
  position: relative;
  background: ${({ theme: { blue } }) => blue};
`;

export const Navigation = styled.nav`
  display: flex;
  height: 100%;
  a,
  div {
    ${flexCenter}
    margin-right: 30px;
    font-weight: 600;
    font-size: 14px;
    line-height: 135%;
    color: ${({ is_black, theme: { white, blackPrimary } }) => (is_black ? blackPrimary : white)};
    position: relative;
    height: 100%;
    opacity: 0.6;
    cursor: pointer;
    &.is_active {
      opacity: 1;
      &::after {
        display: block;
      }
    }
    > svg {
      margin-right: 7px;
    }
    &:last-child {
      margin-right: 0;
    }
    &::after {
      content: '';
      display: none;
      width: calc(100% + 5px);
      height: 2px;
      background: ${({ theme: { blue } }) => blue};
      position: absolute;
      bottom: 0;
    }
  }
`;
