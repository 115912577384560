import { Pie, BarChart, ApiIcon, GraphIcon } from 'components/icons';

import { statisticPath } from 'App.consts';

import { strategicReportPath } from 'components/data.layout/pages/strategicReport/page.consts';
import { walletsStatisticPath } from 'components/data.layout/pages/walletsStatistic/page.consts';
import { apiStatisticPath } from 'components/data.layout/pages/apiStatistic/page.consts';
import { subgraphsInfoPath } from 'components/data.layout/pages/subgraphsInfo/page.consts';

export const navigation = [{ icon: <Pie />, text: 'Strategic Report', to: `/${statisticPath}/${strategicReportPath}` }];

export const adminNavigation = [
  ...navigation,
  { icon: <BarChart isWhite />, text: 'Wallets Statistic', to: `/${statisticPath}/${walletsStatisticPath}` },
  { icon: <ApiIcon />, text: 'Api Statistic', to: `/${statisticPath}/${apiStatisticPath}` },
  { icon: <GraphIcon />, text: 'Subgraphs Info', to: `/${statisticPath}/${subgraphsInfoPath}` },
];
