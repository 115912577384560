import { DoughnutChart, Row } from 'components/common';
import { withRequest } from 'components/hoc/withRequest';

export const PagesAverage = () => {
  const PagesDistribution = withRequest(DoughnutChart, { apiMethodName: 'getAveragePagesDistribution', width: 'calc(50% - 15px)' });

  const TimePerPages = withRequest(DoughnutChart, {
    apiMethodName: 'getAverageTimePerPagesDistribution',
    width: 'calc(50% - 15px)',
  });

  return (
    <Row>
      <PagesDistribution />
      <TimePerPages />
    </Row>
  );
};
