import styled from "styled-components";

import { fullVwVh } from "components/common/mixins";

export const Flex = styled.div`
  display: flex;
  flex-wrap: ${({ isWrap }) => (isWrap ? "wrap" : "nowrap")};
  position: ${({ pos = "relative" }) => pos};
  overflow: ${({ hidden = false, scroll }) => (scroll ? "auto" : hidden ? "hidden" : "visible")};
  background: ${({ bg = "transparent" }) => bg};
`;

export const Column = styled(Flex)`
  flex-direction: column;
  width: ${({ w = "auto" }) => w};
  height: ${({ h = "auto" }) => h};
  padding: ${({ p = 0 }) => p};
  margin: ${({ m = 0 }) => m};
`;

export const Row = styled(Flex)`
  position: ${({ position = "static" }) => position};
  align-items: ${({ align = "flex-start" }) => align};
  justify-content: ${({ justify = "space-between" }) => justify};
  margin: ${({ m = 0 }) => m};
  padding: ${({ p = 0 }) => p};
  width: ${({ w = "auto" }) => w};
  height: ${({ h }) => h};
`;

export const ColorBlock = styled.div`
  width: 28px;
  height: 16px;
  background: ${({ bg = "#68d4ad" }) => bg};
  border-radius: 2px;
  margin-right: 9px;
`;

export const LayoutTransp = styled.section`
  ${fullVwVh}
  background-color: ${({ transparent, theme: { blackPrimary } }) => (transparent ? "transparent" : blackPrimary + "44")};
  padding: ${({ p = 0 }) => p};
`;

export const CenterInfo = styled.div`
  width: 125px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

export const StickyCol = styled(Column)`
  position: sticky;
  top: 30px;
  z-index: 10;
`;

export const Absolute = styled.div`
  position: absolute;
  top: 8px;
  right: 8px;
  cursor: ${({ pointer }) => (pointer ? "pointer" : "auto")};
`;

export const EmptyDoughnut = styled(Flex)`
  width: 180px;
  height: 180px;
  background: ${({ theme: { greyLight } }) => greyLight};
  border-radius: 50%;
  opacity: 0.5;
  position: relative;
  &::before {
    content: "";
    width: 74%;
    height: 74%;
    background: ${({ theme: { white } }) => white};
    border-radius: inherit;
    margin: auto;
  }
`;

export const CounterLabel = styled(Row)`
  background: ${({ theme: { blue } }) => blue};
  color: ${({ theme: { white } }) => white};
  font-size: 10px;
  border-radius: 4px;
`;
