import { Fragment, useState, useEffect } from 'react';
import { useTheme } from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';

import { Row, LightText, Select, SelectMultiple, Button, DarkText } from 'components/common';
import { Filter } from 'components/icons';
import { periodOptionsHashMap, chronoUnitsHashMap, walletsListHashMap, walletsListEnum, platformsHashMap } from 'components/data.layout/pages/strategicReport/page.consts';
import { ExpandedBlock } from 'components/data.layout/pages/strategicReport/styled';
import { setFilters } from 'store/reducers/filters';

export const Filters = ({ is_expanded, onExpand, is_cutted = false }) => {
  const dispatch = useDispatch();
  const { blackPrimary } = useTheme();
  const { walletConnectOptions, uniqueWalletsList, marketingCompanies } = useSelector((s) => s.options);
  const filters = useSelector((s) => s.filters);

  const [startDays, setPeriod] = useState(filters.startDays);
  const [chronoUnit, setChronoUnit] = useState(filters.chronoUnit);
  const [walletLists, setWalletLists] = useState(filters.walletLists);
  const [walletsListType, setWalletsListType] = useState(filters.walletsListType);
  const [providers, setProviders] = useState(filters.providers);
  const [platform, setPlatform] = useState(filters.platform);
  const [uriPart, setUriPart] = useState(filters.uriPart);

  const filtersChanged = JSON.stringify(filters) !== JSON.stringify({ startDays, endDays: 0, chronoUnit, providers, walletLists, walletsListType, uriPart });

  useEffect(() => {
    if (walletsListType === walletsListHashMap.All && walletLists.length) {
      setWalletLists([]);
    }
  }, [walletsListType, walletLists]);

  const onSetReduxFilters = () => {
    dispatch(setFilters({ startDays, endDays: 0, chronoUnit, providers, walletLists, walletsListType, platform, uriPart }));
  };

  return (
    <Fragment>
      <ExpandedBlock is_expanded={is_expanded}>
        <Row justify='flex-start' align='center' m='0 0 15px' className='clickable' onClick={onExpand || undefined}>
          <Filter />
          <DarkText color={blackPrimary} fw='bold' fs={16} m='0 0 0 12px'>
            Filters
          </DarkText>
        </Row>
        <LightText m='0 0 10px'>Report Period</LightText>
        <Select value={startDays} hashMap={periodOptionsHashMap} onChange={setPeriod} />
        {!is_cutted ? (
          <Fragment>
            <LightText m='10px 0'>Units</LightText>
            <Select value={chronoUnit} hashMap={chronoUnitsHashMap} onChange={setChronoUnit} />
            <LightText m='10px 0'>Wallets</LightText>
            <Select value={walletsListType} hashMap={walletsListHashMap} onChange={setWalletsListType} />
            {walletsListType !== walletsListHashMap.All ? (
              <Fragment>
                <LightText m='10px 0'>{walletsListEnum[walletsListType]} Wallets</LightText>
                <SelectMultiple defaultValue={walletLists} values={uniqueWalletsList} onChange={setWalletLists} placeholder={walletsListEnum[walletsListType]} />
              </Fragment>
            ) : null}
            <LightText m='10px 0'>Wallets Connect</LightText>
            <SelectMultiple
              defaultValue={providers}
              values={walletConnectOptions}
              onChange={setProviders}
              placeholder='Connected by'
              emptyPlaceholder='All Providers'
              ordinaryWidth
            />
            <LightText m='10px 0'>Platform</LightText>
            <Select value={platform} hashMap={platformsHashMap} onChange={setPlatform} />
            <LightText m='10px 0'>Marketing Channel</LightText>
            <Select value={uriPart} hashMap={marketingCompanies} onChange={setUriPart} />
          </Fragment>
        ) : null}
        {filtersChanged ? (
          <Button is_border m='20px 0 0' w='100%' onClick={onSetReduxFilters}>
            APPLY
          </Button>
        ) : null}
      </ExpandedBlock>
    </Fragment>
  );
};
