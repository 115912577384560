import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTheme } from 'styled-components';

import { Card, CardHeading, Row, SkelContent, SkeletonBox } from 'components/common';
import Api from 'api';

export const Summary = () => {
  const filters = useSelector((s) => s.filters);
  const { blackPrimary, blue, green, violet, blueDark, red } = useTheme();
  const [
    {
      data: {
        numberOfUniqueVisitors,
        numberSessions,
        totalNumberOfWallets,
        averageTimePerSession,
        averageRecencyByWallet,
        numberOfUniqueUsers,
        numberOfUniqueUsersUsesMobile,
        numberOfUniqueVisitorsUsesMobile,
      },
      isLoad,
      error,
    },
    setSummary,
  ] = useState({ data: {}, isLoad: true, error: '' });

  useEffect(() => {
    const controller = new AbortController();
    setSummary((prev) => ({ ...prev, isLoad: true }));
    Api.getSummaryInfo(filters, controller.signal)
      .then((data) => setSummary({ data, isLoad: false, error: '' }))
      .catch((error) => {
        if (error !== 'canceled') {
          setSummary((prev) => ({ ...prev, isLoad: false, error }));
        }
      });
    return () => controller.abort();
  }, [filters]);

  if (isLoad) return <Skellet />;

  return (
    <Row m='0 0 30px' isWrap>
      <Card h='100px' w='calc(33% - 10px)' p='20px' m='0 0 20px'>
        <CardHeading color={blackPrimary} fs={16} m='0 0 10px'>
          Sessions
        </CardHeading>
        <CardHeading color={error ? red : blue} fs={24}>
          {error || numberSessions}
        </CardHeading>
      </Card>
      <Card h='100px' w='calc(33% - 10px)' p='20px' m='0 0 20px'>
        <CardHeading color={blackPrimary} fs={16} m='0 0 10px'>
          Unique Visitors/Users
        </CardHeading>
        <CardHeading color={error ? red : green} fs={24}>
          {error || `${numberOfUniqueVisitors} / ${numberOfUniqueUsers}`}
        </CardHeading>
      </Card>
      <Card h='100px' w='calc(33% - 10px)' p='20px' m='0 0 20px'>
        <CardHeading color={blackPrimary} fs={16} m='0 0 10px'>
          Unique Visitors/Users Mobile
        </CardHeading>
        <CardHeading color={error ? red : violet} fs={24}>
          {error || `${numberOfUniqueVisitorsUsesMobile} / ${numberOfUniqueUsersUsesMobile}`}
        </CardHeading>
      </Card>
      <Card h='100px' w='calc(33% - 10px)' p='20px'>
        <CardHeading color={blackPrimary} fs={16} m='0 0 10px'>
          Session Avg.
        </CardHeading>
        <CardHeading color={error ? red : blueDark} fs={24}>
          {error || averageTimePerSession}
        </CardHeading>
      </Card>
      <Card h='100px' w='calc(33% - 10px)' p='20px'>
        <CardHeading color={blackPrimary} fs={16} m='0 0 10px'>
          Wallet Recency Avg.
        </CardHeading>
        <CardHeading color={error ? red : blueDark} fs={24}>
          {error || averageRecencyByWallet}
        </CardHeading>
      </Card>
      <Card h='100px' w='calc(33% - 10px)' p='20px'>
        <CardHeading color={blackPrimary} fs={16} m='0 0 10px'>
          Wallets
        </CardHeading>
        <CardHeading color={error ? red : violet} fs={24}>
          {error || totalNumberOfWallets}
        </CardHeading>
      </Card>
    </Row>
  );
};

const Skellet = () => (
  <Row m='0 0 30px' isWrap>
    <SkeletonBox h='100px' w='calc(33% - 10px)' p='20px' m='0 0 20px'>
      <SkelContent m='0 0 10px' w='70%' h='20px' />
      <SkelContent w='30%' h='30px' />
    </SkeletonBox>
    <SkeletonBox h='100px' w='calc(33% - 10px)' p='20px' m='0 0 20px'>
      <SkelContent m='0 0 10px' w='70%' h='20px' />
      <SkelContent w='30%' h='30px' />
    </SkeletonBox>
    <SkeletonBox h='100px' w='calc(33% - 10px)' p='20px' m='0 0 20px'>
      <SkelContent m='0 0 10px' w='70%' h='20px' />
      <SkelContent w='30%' h='30px' />
    </SkeletonBox>
    <SkeletonBox h='100px' w='calc(33% - 10px)' p='20px'>
      <SkelContent m='0 0 10px' w='70%' h='20px' />
      <SkelContent w='30%' h='30px' />
    </SkeletonBox>
    <SkeletonBox h='100px' w='calc(33% - 10px)' p='20px'>
      <SkelContent m='0 0 10px' w='70%' h='20px' />
      <SkelContent w='30%' h='30px' />
    </SkeletonBox>
    <SkeletonBox h='100px' w='calc(33% - 10px)' p='20px'>
      <SkelContent m='0 0 10px' w='70%' h='20px' />
      <SkelContent w='30%' h='30px' />
    </SkeletonBox>
  </Row>
);
