import { Fragment, useState, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import { useSelector } from 'react-redux';

import { Card, CardHeading, DarkText, LightText, Row, Column, ColorBlock, CrossLine, SkelContent, SkeletonBox, ErrorKpi, Absolute, Button, ModalBg } from 'components/common';
import { Expand } from 'components/icons';
import { createLineOptions, colors } from 'components/common/lineChart/helpers';
import { useToggle } from 'helpers/hooks';
import Api from 'api';

export const LineChart = ({ apiMethodName = 'getNumberOfWallets', width = '100%', chartColors = colors, height = '390px' }) => {
  const filters = useSelector((s) => s.filters);
  const [fullView, setFull] = useToggle();
  const [
    {
      data: { title, plots },
      load,
      error,
    },
    setKpi,
  ] = useState({ load: true, error: '', data: { title: '', plots: null } });

  useEffect(() => {
    const controller = new AbortController();

    setKpi((prev) => ({ ...prev, load: true, error: '' }));
    Api[apiMethodName](filters, controller.signal)
      .then((data) => setKpi((prev) => ({ ...prev, data, load: false, error: '' })))
      .catch((error) => {
        if (error !== 'canceled') {
          setKpi((prev) => ({ ...prev, load: false, error }));
        }
      });

    return () => controller.abort();
  }, [filters, apiMethodName]);

  if (load)
    return (
      <SkeletonBox w={width} h={height} m='0 0 30px' p='16px 20px'>
        <SkelContent h='18px' m='0 0 31px' />
        <SkelContent h='242px' m='0 0 31px' />
      </SkeletonBox>
    );

  if (error) return <ErrorKpi width={width} message={error} />;

  return (
    <Fragment>
      <Card h={height} visible m='0 0 30px' w={width} p='16px 20px 0'>
        <Row>
          <CardHeading>{title}</CardHeading>
        </Row>
        <CrossLine parentPadding={20} />
        <Row m='5px auto 0' position='relative' h='242px' w='100%'>
          <Line {...createLineOptions(plots.Performance, false, chartColors, true, filters.chronoUnit === 'HOURS' ? 'DD MMM YYYY, hh:mm A' : 'DD MMM YYYY')} />
          <Absolute>
            <Button no_text is_fill w='24px' z={3} h='24px' onClick={setFull}>
              <Expand />
            </Button>
          </Absolute>
        </Row>
        <Row hidden isWrap m='24px 0 0' justify='flex-start'>
          {Object.entries(plots.Performance.summary || {}).map(([key, val], index, { length }) => (
            <Row w={length < 4 ? 'fit-content' : `calc(${100 / Math.ceil(length / 2)}% - 10px)`} m='0 0 16px' justify='flex-start' key={key}>
              <ColorBlock bg={chartColors[index]} />
              <Column w='calc(100% - 37px)'>
                <DarkText m='0 0 2px' fs={12}>
                  {key}
                </DarkText>
                <LightText fw={500} fs={12}>
                  {val}
                </LightText>
              </Column>
            </Row>
          ))}
        </Row>
      </Card>
      {fullView && (
        <ModalBg>
          <Card h='100%' p='30px'>
            <Row m='5px auto 0' position='relative' h='100%' w='100%'>
              <Line {...createLineOptions(plots.Performance, true, chartColors, true, filters.chronoUnit === 'HOURS' ? 'DD MMM YYYY, hh:mm A' : 'DD MMM YYYY')} />
              <Absolute>
                <Button no_text is_fill w='24px' z={3} h='24px' onClick={setFull}>
                  <Expand />
                </Button>
              </Absolute>
            </Row>
          </Card>
        </ModalBg>
      )}
    </Fragment>
  );
};

export const LineChartComponent = ({ data: { title, plots }, error, width = '100%', chartColors = colors, height = '390px' }) => {
  const filters = useSelector((s) => s.filters);
  const [fullView, setFull] = useToggle();

  if (error) return <ErrorKpi width={width} message={error} />;

  return (
    <Fragment>
      <Card h={height} visible m='0 0 30px' w={width} p='16px 20px 0'>
        <Row>
          <CardHeading>{title}</CardHeading>
        </Row>
        <CrossLine parentPadding={20} />
        <Row m='5px auto 0' position='relative' h='242px' w='100%'>
          <Line {...createLineOptions(plots.Performance, false, chartColors, true, filters.chronoUnit === 'HOURS' ? 'DD MMM YYYY, hh:mm A' : 'DD MMM YYYY')} />
          <Absolute>
            <Button no_text is_fill w='24px' z={3} h='24px' onClick={setFull}>
              <Expand />
            </Button>
          </Absolute>
        </Row>
        <Row hidden isWrap m='24px 0 0' justify='flex-start'>
          {Object.entries(plots.Performance.summary || {}).map(([key, val], index, { length }) => (
            <Row w={length < 4 ? 'fit-content' : `calc(${100 / Math.ceil(length / 2)}% - 10px)`} m='0 0 16px' justify='flex-start' key={key}>
              <ColorBlock bg={chartColors[index]} />
              <Column w='calc(100% - 37px)'>
                <DarkText m='0 0 2px' fs={12}>
                  {key}
                </DarkText>
                <LightText fw={500} fs={12}>
                  {val}
                </LightText>
              </Column>
            </Row>
          ))}
        </Row>
      </Card>
      {fullView && (
        <ModalBg>
          <Card h='100%' p='30px'>
            <Row m='5px auto 0' position='relative' h='100%' w='100%'>
              <Line {...createLineOptions(plots.Performance, true, chartColors, true, filters.chronoUnit === 'HOURS' ? 'DD MMM YYYY, hh:mm A' : 'DD MMM YYYY')} />
              <Absolute>
                <Button no_text is_fill w='24px' z={3} h='24px' onClick={setFull}>
                  <Expand />
                </Button>
              </Absolute>
            </Row>
          </Card>
        </ModalBg>
      )}
    </Fragment>
  );
};
