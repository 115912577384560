import styled, { keyframes } from "styled-components";

const spin = keyframes`
  0%   { transform: rotate(0deg);   transform: rotate(0deg); }
  100% { transform: rotate(360deg); transform: rotate(360deg); }
`;

const Container = styled.div`
  left: calc(50% - 20px);
  top: calc(50% - 20px);
  position: absolute;
  animation: ${spin} 0.7s linear infinite;
`;

const Spinner = styled.div`
  width: 40px;
  height: 40px;
  border: solid 4px transparent;
  border-top-color: ${({ theme: { blue } }) => blue};
  border-left-color: ${({ theme: { blue } }) => blue};
  border-radius: 50%;
`;

export const Loader = () => (
  <Container>
    <Spinner />
  </Container>
);
