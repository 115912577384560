import { useState, useCallback, useEffect, useRef } from 'react';

export const useToggle = (initialState = false) => {
  const [state, setState] = useState(initialState);

  const toggle = useCallback(() => setState((state) => !state), []);

  return [state, toggle];
};

export const useDebounceState = (initialValue, debounceTime = 450, cb) => {
  const [state, setState] = useState(initialValue);
  const debounceId = useRef(null);
  const _setState = useCallback(
    (value) => {
      clearTimeout(debounceId.current);

      debounceId.current = setTimeout(() => {
        setState(value);
        cb && typeof cb === 'function' && cb();
      }, debounceTime);
    },
    [debounceTime, cb]
  );

  useEffect(() => {
    return () => {
      clearTimeout(debounceId.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [state, _setState];
};
