import { useSelector } from 'react-redux';
import { useTheme } from 'styled-components';

import { Column, Row, Card, StickyCol, Filters, LineChart, BarChart, MultipleBar } from 'components/common';

export const ApiStatistic = () => {
  const role = useSelector((s) => s.user.role);
  const { gold, violet, orange, blueDark, red, green, greenLight, violetLight, blue } = useTheme();

  const isAdmin = role === 'ADMIN';

  if (!isAdmin) return null;

  return (
    <Row position='relative'>
      <StickyCol w='323px'>
        <Card p='20px 25px' visible>
          <Filters is_expanded is_cutted />
        </Card>
      </StickyCol>
      <Column w='calc(100% - 353px)'>
        <Row>
          <LineChart {...{ width: 'calc(50% - 15px)', apiMethodName: 'getUsersCountByDay', chartColors: [blue, blueDark, gold, red, green, violet, greenLight, violetLight] }} />
          <LineChart
            {...{ width: 'calc(50% - 15px)', apiMethodName: 'getEndpointsCountByDay', chartColors: [violet, blueDark, gold, orange, red, green, greenLight, violetLight] }}
          />
        </Row>
        <Row>
          <BarChart apiMethodName='getUsersByUnitsDistribution' width='calc(50% - 15px)' withLabels />
          <BarChart apiMethodName='getEndpointsDistribution' width='calc(50% - 15px)' withLabels />
        </Row>
        <MultipleBar />
        <MultipleBar apiMethodName='getUsersActivityByDayBarCost' />
      </Column>
    </Row>
  );
};
