import React from "react";
import ReactDOM from "react-dom";
import styled, { useTheme } from "styled-components";

import { Card, LayoutTransp, Button, CardHeading, LightText, Row, Column } from "components/common";
import { flexCenter } from "components/common/mixins";
import { Info } from "components/icons";

const BG = styled(LayoutTransp)`
  ${flexCenter}
`;

export const CardHeader = styled(Row)`
  height: 70px;
  border-radius: 4px 4px 0px 0px;
  background: ${({ theme: { greyCard } }) => greyCard};
`;

const IconBlock = styled(Row)`
  position: absolute;
  width: 70px;
  height: 70px;
  left: calc(50% - 35px);
  top: 25px;
  border-radius: 50%;
  background: ${({ theme: { blue } }) => blue};
`;

const Wrapper = styled(Column)`
  align-items: center;
  justify-content: space-between;
`;

export const Modal = ({ onSubmit, heading = "Your Session has expired", submitText = "GO TO LOGIN", message = "Please, Sign In again" }) => {
  const { blackPrimary } = useTheme();

  return ReactDOM.createPortal(
    <BG>
      <Card w="340px" m="auto">
        <CardHeader>
          <IconBlock align="center" justify="center">
            <Info />
          </IconBlock>
        </CardHeader>
        <Wrapper p="40px 30px 25px" h="calc(100% - 70px)">
          <CardHeading fs={16} color={blackPrimary} m="0 0 20px" align="center">
            {heading}
          </CardHeading>
          <Column hidden>
            <LightText color={blackPrimary} align="center" fs={12} fw={400} isWrap dangerouslySetInnerHTML={{ __html: message }} />
          </Column>
          <Button fs={12} m="20px auto 0" w="180px" is_fill onClick={onSubmit}>
            {submitText}
          </Button>
        </Wrapper>
      </Card>
    </BG>,
    document.getElementById("info-portal")
  );
};
