import styled from "styled-components";
import { border4, flexCenter, transition } from "components/common/mixins";

export const Button = styled.button`
  ${flexCenter}
  ${transition}
  ${border4};
  background: ${({ is_fill, theme: { blue } }) => (is_fill ? blue : "transparent")};
  border: 1px solid transparent;
  border-color: ${({ is_border, is_fill, is_red, theme: { blue, red } }) => (is_red ? red : is_border || is_fill ? blue : "transparent")};
  width: ${({ w = "90px" }) => w};
  height: ${({ h = "32px" }) => h};
  z-index: ${({ z = "auto" }) => z};
  margin: ${({ m = 0 }) => m};
  outline: none;
  color: ${({ is_border, is_red, theme: { blue, red } }) => (is_red ? red : is_border ? blue : "#fff")};
  font-size: ${({ fs = 12 }) => `${fs}px`};
  font-weight: 500;
  letter-spacing: -0.02em;
  cursor: pointer;
  position: relative;
  padding: 0;
  &:hover {
    background: ${({ is_red, theme: { blue, red } }) => (is_red ? red : blue)};
    border-color: ${({ is_red, theme: { blue, red } }) => (is_red ? red : blue)};
    color: #fff;
    svg {
      path {
        stroke: #fff;
      }
    }
  }
  &:active {
    transform: scale(0.95);
    filter: grayscale(0.3);
  }
  &:disabled {
    background: #e3e3e3;
    border: #e3e3e3;
    color: #fff;
  }
  svg {
    width: ${({ fs = 12 }) => `${fs * 1.2}px`};
    height: ${({ fs = 12 }) => `${fs * 1.2}px`};
    margin-right: ${({ no_text = false, fs = 12 }) => (no_text ? 0 : `${fs / 2}px`)};
    path {
      ${transition}
      stroke: ${({ is_border, theme: { blue } }) => (is_border ? blue : "#fff")};
    }
  }
`;
