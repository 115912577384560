import { useRoutes } from "react-router-dom";

import { ROUTES } from "router";

function App() {
  const Router = useRoutes(ROUTES);

  return Router;
}

export default App;
