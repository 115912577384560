import { Fragment } from 'react';

import { PaginatorRow } from 'components/data.layout/pages/walletsStatistic/styled';
import { Table, TH, TD } from 'components/data.layout/pages/walletsStatistic/styled';
import { SkelContent } from 'components/common';

export const PageSkeleton = () => {
  const renderRows = () => {
    return Array.from(Array(20).keys()).map((el) => {
      return (
        <tr key={el}>
          <TD className='break-word'>
            <SkelContent h='14.5px' />
          </TD>
          <TD className='break-word'>
            <SkelContent h='14.5px' />
          </TD>
          <TD className='break-word'>
            <SkelContent h='14.5px' />
          </TD>
          <TD className='break-word'>
            <SkelContent h='14.5px' />
          </TD>
          <TD className='break-word'>
            <SkelContent h='14.5px' />
          </TD>
          <TD className='break-word'>
            <SkelContent h='14.5px' />
          </TD>
          <TD className='break-word'>
            <SkelContent h='14.5px' />
          </TD>
          <TD className='break-word'>
            <SkelContent h='14.5px' />
          </TD>
          <TD className='break-word'>
            <SkelContent h='14.5px' />
          </TD>
          <TD className='break-word'>
            <SkelContent h='14.5px' />
          </TD>
          <TD className='break-word'>
            <SkelContent h='14.5px' />
          </TD>
        </tr>
      );
    });
  };

  return (
    <Fragment>
      <Table style={{ tableLayout: 'fixed', overflowWrap: 'break-word', width: '100%' }}>
        <thead>
          <tr>
            <TH className='sorted start-align'>
              <span>Name</span>
            </TH>
            <TH className='sorted start-align'>
              <span>Chain</span>
            </TH>
            <TH className='sorted start-align'>
              <span>Created</span>
            </TH>
            <TH className='sorted start-align'>
              <span>Updated</span>
            </TH>

            <TH className='sorted start-align'>
              <span>Synced On Block</span>
            </TH>
            <TH className='sorted start-align'>
              <span>Block Number Diff.</span>
            </TH>
            <TH className='sorted start-align'>
              <span>Events</span>
            </TH>
            <TH className='sorted start-align'>
              <span>Server</span>
            </TH>
            <TH className='sorted start-align'>
              <span>Status</span>
            </TH>
            <TH className='sorted start-align'>
              <span>Info</span>
            </TH>
            <TH className='start-align'>
              <span>Comment</span>
            </TH>
          </tr>
        </thead>
        <tbody>{renderRows()}</tbody>
      </Table>
      <PaginatorRow m='0 auto' justify='center' w='100%'>
        <SkelContent w='276px' h='24px' m='16px 0' />
      </PaginatorRow>
    </Fragment>
  );
};
