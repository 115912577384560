import { combineReducers, configureStore } from "@reduxjs/toolkit";

import user, { createState as createUserState } from "store/reducers/user";
import session, { createState as createSessionState } from "store/reducers/session";
import options, { createState as createOptionsStore } from "store/reducers/options";
import filters, { createState as createFiltersStore } from "store/reducers/filters";

const combinedReducer = combineReducers({ session, user, options, filters });

const rootReducer = (state, action) => {
  if (action.type === "reset-store") {
    localStorage.removeItem("merlin-token");
    return {
      session: createSessionState(),
      user: createUserState(),
      options: createOptionsStore(),
      filters: createFiltersStore(),
    };
  }
  return combinedReducer(state, action);
};

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }),
});
