import { useEffect, useState, useMemo, Fragment } from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import ReactPaginate from 'react-paginate';
import ReactTooltip from 'react-tooltip';
import { useTheme } from 'styled-components';

import { TableContainer, Table, TH, TD, PaginatorRow, CircleImage } from 'components/data.layout/pages/walletsStatistic/styled';
import { Column, Row, Card, StickyCol, Filters, Button, DarkText } from 'components/common';
import { ArrowDown, Copy, Check } from 'components/icons';
import twitterIcon from 'assets/twitter.png';
import debankIcon from 'assets/debank.png';

import Api from 'api';
import { splitWalletAddress, numberWithCommas } from 'helpers';

export const WalletsStatistic = () => {
  const role = useSelector((s) => s.user.role);
  const filters = useSelector((s) => s.filters);
  const [data, setData] = useState([]);
  const [order, setOrder] = useState('DESC');
  const [sortKey, setSortKey] = useState('VISITS');
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(true);
  const [loadingExcel, setLoadingExcel] = useState(false);

  const isAdmin = role === 'ADMIN';
  const portion = 15;
  const pageCount = Math.ceil(data.length / portion);

  const onDownloadExcel = async () => {
    setLoadingExcel(true);
    const result = await Api.downloadWalletsExcel(filters).finally(() => setLoadingExcel(false));
    if (result) {
      const { file, fileName } = result;
      const link = document.createElement('a');
      link.href = file;
      link.download = fileName;
      link.click();
    }
  };

  const onChangeOrder = () => setOrder(order === 'DESC' ? 'ASC' : 'DESC');

  const onPageChange = ({ selected }) => setPage(selected);

  const onChangeSortKey = (key) => () => {
    setPage(0);
    if (key === sortKey) {
      onChangeOrder();
    } else {
      setSortKey(key);
    }
  };

  const TableRows = useMemo(() => {
    const start = page * portion;

    return data
      .sort((a, b) => {
        if (sortKey === 'VISITS') {
          return order === 'ASC' ? a.visitsCount - b.visitsCount : b.visitsCount - a.visitsCount;
        } else if (sortKey === 'FIRST_VISIT') {
          const aTimeStamp = moment(a.firstVisit).format('x');
          const bTimeStamp = moment(b.firstVisit).format('x');
          return order === 'ASC' ? aTimeStamp - bTimeStamp : bTimeStamp - aTimeStamp;
        } else if (sortKey === 'LAST_VISIT') {
          const aTimeStamp = moment(a.lastVisit).format('x');
          const bTimeStamp = moment(b.lastVisit).format('x');
          return order === 'ASC' ? aTimeStamp - bTimeStamp : bTimeStamp - aTimeStamp;
        } else if (sortKey === 'VALUE') {
          return order === 'ASC' ? a.totalValueUsd - b.totalValueUsd : b.totalValueUsd - a.totalValueUsd;
        } else if (sortKey === 'PROTOCOLS') {
          return order === 'ASC' ? a.protocols.length - b.protocols.length : b.protocols.length - a.protocols.length;
        } else if (sortKey === 'TXS') {
          return order === 'ASC' ? a.totalTxs - b.totalTxs : b.totalTxs - a.totalTxs;
        } else if (sortKey === 'RANK') {
          return order === 'ASC' ? a.rank - b.rank : b.rank - a.rank;
        }
        return 1;
      })
      .slice(start, start + portion)
      .map((walletInfo, i) => <WalletRow key={walletInfo.wallet + i} walletInfo={walletInfo} />);
  }, [data, page, sortKey, order]);

  useEffect(() => {
    if (isAdmin) {
      setLoading(true);

      const controller = new AbortController();
      Api.getWalletsStatistic(filters, controller.signal)
        .then(setData)
        .catch(console.log)
        .finally(() => setLoading(false));

      return () => controller.abort();
    }
  }, [isAdmin, filters]);

  if (!isAdmin) return null;

  return (
    <Row position='relative'>
      <StickyCol w='323px'>
        <Card p='20px 25px' visible>
          <Filters is_expanded />
          {!loading && data.length ? (
            <Button is_fill disabled={loadingExcel} onClick={onDownloadExcel} w='100%' m='20px 0 0'>
              {loadingExcel ? 'Downloading' : 'Download XLS'}
            </Button>
          ) : null}
        </Card>
      </StickyCol>
      <Column w='calc(100% - 353px)'>
        <TableContainer>
          {loading ? (
            <div>Loading...</div>
          ) : (
            <Fragment>
              <Table>
                <thead>
                  <tr>
                    <TH>Wallet</TH>
                    <TH className='sorted' onClick={onChangeSortKey('RANK')} is_asc={order === 'ASC'} is_sorted={sortKey === 'RANK'}>
                      <span>Debank Ranking</span>
                      <ArrowDown />
                    </TH>
                    <TH className='sorted' onClick={onChangeSortKey('VISITS')} is_asc={order === 'ASC'} is_sorted={sortKey === 'VISITS'}>
                      <span>Visits</span>
                      <ArrowDown />
                    </TH>
                    <TH className='sorted' onClick={onChangeSortKey('FIRST_VISIT')} is_asc={order === 'ASC'} is_sorted={sortKey === 'FIRST_VISIT'}>
                      <span>First Visit</span>
                      <ArrowDown />
                    </TH>
                    <TH className='sorted' onClick={onChangeSortKey('LAST_VISIT')} is_asc={order === 'ASC'} is_sorted={sortKey === 'LAST_VISIT'}>
                      <span>Last Visit</span>
                      <ArrowDown />
                    </TH>
                    <TH>Average Interval</TH>
                    <TH className='sorted' onClick={onChangeSortKey('VALUE')} is_asc={order === 'ASC'} is_sorted={sortKey === 'VALUE'}>
                      <span>Total Value</span>
                      <ArrowDown />
                    </TH>
                    <TH className='sorted' onClick={onChangeSortKey('PROTOCOLS')} is_asc={order === 'ASC'} is_sorted={sortKey === 'PROTOCOLS'}>
                      <span>Protocols</span>
                      <ArrowDown />
                    </TH>
                    <TH className='sorted' onClick={onChangeSortKey('TXS')} is_asc={order === 'ASC'} is_sorted={sortKey === 'TXS'}>
                      <span>Txs Number</span>
                      <ArrowDown />
                    </TH>
                  </tr>
                </thead>
                <tbody>{TableRows}</tbody>
              </Table>
              {pageCount > 1 ? (
                <PaginatorRow m='0 auto' justify='center' w='100%'>
                  <ReactPaginate
                    {...{
                      breakLabel: '...',
                      nextLabel: '>',
                      forcePage: page,
                      onPageChange: onPageChange,
                      pageRangeDisplayed: 3,
                      pageCount,
                      previousLabel: '<',
                      className: 'paginator',
                    }}
                  />
                </PaginatorRow>
              ) : null}
            </Fragment>
          )}
        </TableContainer>
      </Column>
    </Row>
  );
};

const WalletRow = ({
  walletInfo: { wallet, visitsCount, totalValueUsd, totalTxs, protocols, lastVisit, firstVisit, averageInterval, debankLink, twitter, rank, thirdPartyName },
}) => {
  const { white, greyTert } = useTheme();
  const [isCopiedMessage, setCopiedMessage] = useState(false);

  const onCopyWallet = () => {
    navigator.clipboard
      .writeText(wallet)
      .then(() => setCopiedMessage(true))
      .then(() => {
        setTimeout(() => {
          setCopiedMessage(false);
        }, 2000);
      });
  };

  return (
    <tr>
      <TD className='wallet-cell' onClick={onCopyWallet}>
        <Row justify='flex-start' m='0 0 7px'>
          {splitWalletAddress(wallet)} {isCopiedMessage ? <Check /> : <Copy />}
        </Row>
        {thirdPartyName ? (
          <Row justify='flex-start' m='0 0 7px'>
            {thirdPartyName}
          </Row>
        ) : null}
        {twitter || debankLink ? (
          <Row align='center' w='36px'>
            {debankLink ? (
              <a href={debankLink} target='_blank' rel='noreferrer'>
                <CircleImage src={debankIcon} alt='debank' data-tip data-for={debankLink} />
                <ReactTooltip id={debankLink} backgroundColor={white} arrowColor={white} borderColor={greyTert} border={true} place='top' effect='solid'>
                  <DarkText fw={400} fs={12}>
                    {debankLink}
                  </DarkText>
                </ReactTooltip>
              </a>
            ) : null}
            {twitter ? (
              <a href={`https://twitter.com/${twitter}`} target='_blank' rel='noreferrer'>
                <CircleImage src={twitterIcon} alt='twitter' data-tip data-for={twitter} />
                <ReactTooltip id={twitter} backgroundColor={white} arrowColor={white} borderColor={greyTert} border={true} place='top' effect='solid'>
                  <DarkText fw={400} fs={12}>
                    @{twitter}
                  </DarkText>
                </ReactTooltip>
              </a>
            ) : null}
          </Row>
        ) : null}
      </TD>
      <TD>{rank || '-'}</TD>
      <TD>{visitsCount}</TD>
      <TD>{moment(firstVisit).format('DD.MM.YYYY, hh:mm A')}</TD>
      <TD>{moment(lastVisit).format('DD.MM.YYYY, hh:mm A')}</TD>
      <TD>{averageInterval}</TD>
      <TD>${numberWithCommas(totalValueUsd)}</TD>
      <TD>{(protocols || []).join(', ') || '-'}</TD>
      <TD>{numberWithCommas(totalTxs)}</TD>
    </tr>
  );
};
