import styled from 'styled-components';

import { border4 } from 'components/common/mixins';
import { Column } from 'components/common';

export const Card = styled(Column)`
  ${border4}
  display: flex;
  background: ${({ theme: { white }, bg }) => bg || white};
  overflow: ${({ visible = false }) => (visible ? 'visible' : 'hidden')};
  border: 1px solid ${({ theme: { greyBorder } }) => greyBorder};
  position: relative;
`;

export const HeaderCard = styled(Card)`
  background: ${({ theme: { greyCard } }) => greyCard};
`;
