import { useTheme } from "styled-components";
import { Outlet } from "react-router-dom";

import { Column } from "components/common";
import { ProtectedRoute } from "auth.panel/protectedRoute";

export const AuthApp = () => {
  const { white } = useTheme();

  return (
    <ProtectedRoute>
      <Column w="100vw" h="100vh" bg={`linear-gradient(to top, ${white} 58%, transparent 42%)`}>
        <Column w="440px" m="auto">
          <Outlet />
        </Column>
      </Column>
    </ProtectedRoute>
  );
};
