import { Fragment, useState, useEffect } from 'react';
import { useTheme } from 'styled-components';
import { useSelector } from 'react-redux';
import { Chart } from 'react-google-charts';

import { Card, CardHeading, Row, CrossLine, SkelContent, SkeletonBox, ErrorKpi } from 'components/common';
import Api from 'api';

export const GeoChart = ({ apiMethodName = 'getIpsByCountriesV2' }) => {
  const filters = useSelector((s) => s.filters);
  const [
    {
      data: { title, chartData },
      load,
      error,
    },
    setKpi,
  ] = useState({ load: true, error: '', data: { title: '', chartData: null } });

  useEffect(() => {
    const controller = new AbortController();

    setKpi((prev) => ({ ...prev, load: true, error: '' }));
    Api[apiMethodName](filters, controller.signal)
      .then((data) => setKpi((prev) => ({ ...prev, data, load: false, error: '' })))
      .catch((error) => {
        if (error !== 'canceled') {
          setKpi((prev) => ({ ...prev, load: false, error }));
        }
      });

    return () => controller.abort();
  }, [filters, apiMethodName]);

  const { blueDark } = useTheme();

  if (load || !chartData)
    return (
      <SkeletonBox w='100%' h='595px' m='0 0 30px' p='16px 20px 24px'>
        <SkelContent h='18px' m='0 0 31px' />
        <SkelContent h='500px' />
      </SkeletonBox>
    );

  if (error) return <ErrorKpi width='100%' message={error} />;

  return (
    <Fragment>
      <Card h='595px' visible m='0 0 30px' w='100%' p='16px 20px 24px'>
        <Row>
          <CardHeading>{title}</CardHeading>
        </Row>
        <CrossLine parentPadding={20} />
        <Row m='5px auto 0' position='relative' h='500px' w='100%' className='geo-chart'>
          <Chart chartType='GeoChart' width='100%' height='500px' data={chartData} options={{ colors: [blueDark], backgroundColor: '#F7F9FB' }} />
        </Row>
      </Card>
    </Fragment>
  );
};
