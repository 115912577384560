import { createSlice } from '@reduxjs/toolkit';

export const createState = () => ({
  startDays: 30,
  endDays: 0,
  chronoUnit: 'DAYS',
  providers: [],
  walletLists: [],
  walletsListType: 'ALL',
  platform: 'ALL',
  uriPart: 'all',
});

const filtersSlice = createSlice({
  name: 'filters',
  initialState: createState(),
  reducers: {
    setFilters: (state, { payload: { startDays, endDays, chronoUnit, providers, walletLists, walletsListType, platform, uriPart } }) => {
      state.startDays = startDays;
      state.endDays = endDays;
      state.chronoUnit = chronoUnit;
      state.providers = providers;
      state.walletLists = walletLists;
      state.walletsListType = walletsListType;
      state.platform = platform;
      state.uriPart = uriPart;
    },
  },
});

export const { setFilters } = filtersSlice.actions;
export default filtersSlice.reducer;
