import { useState, useEffect, useRef, useCallback, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactPaginate from 'react-paginate';
import moment from 'moment';

import { TableContainer, Table, TH, TD, PaginatorRow } from 'components/data.layout/pages/walletsStatistic/styled';
import { Navigation } from 'data.panel/styled';
import { ArrowDown } from 'components/icons';
import { Input } from 'components/common/select/styled';
import { CardHeading, Row, Absolute, Card, Select } from 'components/common';
import { PageSkeleton } from 'components/data.layout/pages/subgraphsInfo/skeleton';
import { Cross } from 'components/icons';
import { statusColors, statusIcons, FilterTypes } from 'components/data.layout/pages/subgraphsInfo/page.consts';
import Api from 'api';
import { capitalizeFirstLetter } from 'helpers';
import { useDebounceState } from 'helpers/hooks';
import { setSubgraphsParams } from 'store/reducers/options';
import { createHashMap, allKey } from 'components/data.layout/pages/subgraphsInfo/helpers';

export const SubgraphsInfo = () => {
  const {
    data: { chain: chainValues, protocol: protocolValues },
    loaded: optionsLoaded,
  } = useSelector((s) => s.options.subgraphsParams);
  const dispatch = useDispatch();
  const tabs = Object.keys(FilterTypes);
  const role = useSelector((s) => s.user.role);
  const [activeTab, setActiveTab] = useState(tabs[0]);
  const [data, setData] = useState([]);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);

  const [sortOrder, setSortOrder] = useState('DESC');
  const [sortBy, setSortBy] = useState('blockNumberDifference');
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const resetPage = useCallback(() => setPage(0), []);
  const [search, setSearch] = useDebounceState('', 450, resetPage);
  const [totalElements, setTotalElements] = useState(0);
  const [chain, setChain] = useState(allKey);
  const [protocol, setProtocol] = useState(allKey);
  const inpuRef = useRef(null);
  const pageSize = 20;
  const isAdmin = role === 'ADMIN';

  //local filter values if searc exist
  const [chainValuesLocal, setChainValuesLocal] = useState([]);
  const [protocolValuesLocal, setProtocolValuesLocal] = useState([]);

  const chainsHashMap = createHashMap(search ? chainValuesLocal : chainValues, 'All Chains');
  const protocolsHashMap = createHashMap(search ? protocolValuesLocal : protocolValues, 'All Protocols');

  const onChangeActiveTab = (nextTab) => () => {
    const nextTabKey = FilterTypes[nextTab];
    if (nextTabKey === 'prod' || nextTabKey === 'dev') {
      setSortOrder(nextTabKey === 'prod' ? 'DESC' : 'ASC');
      setSortBy('blockNumberDifference');
    } else {
      setSortOrder('ASC');
      setSortBy('name');
    }
    resetPage();
    onClearSearch();
    setChain(allKey);
    setProtocol(allKey);
    setActiveTab(nextTab);
  };

  const onChangeOrder = () => setSortOrder(sortOrder === 'DESC' ? 'ASC' : 'DESC');

  const onPageChange = ({ selected }) => setPage(selected);

  const onChangeSortBy = (key) => () => {
    setPage(0);
    if (key === sortBy) {
      onChangeOrder();
    } else {
      setSortBy(key);
    }
  };

  const onChangeSearch = (e) => {
    setSearch(e.target.value);
    setChain(allKey);
    setProtocol(allKey);
  };

  const onClearSearch = () => {
    if (inpuRef && inpuRef.current) {
      try {
        inpuRef.current.value = '';
      } catch (error) {
        console.log(error);
      }
    }
    setSearch('');
    setChain(allKey);
    setProtocol(allKey);
  };

  const renderRows = () => {
    return data.map((el, index) => {
      return <SubgraphRow key={el.id + index} subgraph={el} onManualUpdateComment={onManualUpdateComment} index={index} />;
    });
  };

  const onManualUpdateComment = (subgraphIndex, subgraphId, nextComment) => {
    const nexData = [...data];
    if (nexData[subgraphIndex] && nexData[subgraphIndex].id === subgraphId) {
      nexData[subgraphIndex].comment = nextComment;
      setData(nexData);
    }
  };

  const renderTabs = () =>
    tabs.map((el) => (
      <div onClick={onChangeActiveTab(el)} key={el} className={el === activeTab ? 'is_active' : ''}>
        {el}
      </div>
    ));

  useEffect(() => {
    if (isAdmin) {
      setLoading(true);
      setError('');
      const controller = new AbortController();
      Api.getSubgraphsInfo(
        { page, sortBy, sortOrder, pageSize, search, tab: FilterTypes[activeTab], chain: chain === allKey ? null : chain, protocol: protocol === allKey ? null : protocol },
        controller.signal
      )
        .then(({ content, totalPages, number, totalElements }) => {
          setData(content);
          setTotalPages(totalPages);
          setTotalElements(totalElements);
          setPage(number);
        })
        .catch((err) => {
          if (err !== 'canceled') {
            setError(err);
          }
        })
        .finally(() => setLoading(false));

      return () => controller.abort();
    }
  }, [isAdmin, page, sortBy, sortOrder, pageSize, search, activeTab, chain, protocol]);

  useEffect(() => {
    if (isAdmin && !optionsLoaded) {
      Promise.all(['chain', 'protocol'].map((param) => Api.getPossibleSubgraphsParams(param))).then(([chain, protocol]) => {
        dispatch(setSubgraphsParams({ chain, protocol }));
      });
    }
  }, [isAdmin, optionsLoaded, dispatch]);

  useEffect(() => {
    if (isAdmin) {
      if (search) {
        Promise.all(['chain', 'protocol'].map((param) => Api.getPossibleSubgraphsParams(param, search))).then(([chain, protocol]) => {
          setChainValuesLocal(chain);
          setProtocolValuesLocal(protocol);
        });
      } else {
        setChainValuesLocal([]);
        setProtocolValuesLocal([]);
      }
    }
  }, [isAdmin, search]);

  if (!isAdmin) return null;

  return (
    <TableContainer>
      <Card h='60px' m='0 0 20px' p='0 15px'>
        <Navigation is_black>{renderTabs()}</Navigation>
      </Card>
      <Card visible m='0 0 20px' p='15px'>
        <Row align='center' justify='flex-start'>
          <Row w='250px' m='0 20px 0 0' position='relative'>
            <Input autoFocus={true} ref={inpuRef} disabled={loading} placeholder='Search...' fw={400} fs={12} defaultValue={search} onChange={onChangeSearch} />
            {search ? (
              <Absolute style={{ top: 11 }} pointer onClick={onClearSearch}>
                <Cross size={16} />
              </Absolute>
            ) : null}
          </Row>
          <div style={{ width: 180, marginRight: 20 }}>
            <Select hashMap={chainsHashMap} onChange={setChain} value={chain} />
          </div>
          <div style={{ width: 180, marginRight: 'auto' }}>
            <Select hashMap={protocolsHashMap} onChange={setProtocol} value={protocol} />
          </div>
          <CardHeading>Total Elements: {totalElements}</CardHeading>
        </Row>
      </Card>
      {loading ? (
        <PageSkeleton />
      ) : error ? (
        <div style={{ color: 'red' }}>{error}</div>
      ) : data.length ? (
        <Fragment>
          <Table style={{ tableLayout: 'fixed', overflowWrap: 'break-word', width: '100%' }}>
            <thead>
              <tr>
                <TH className='sorted start-align' onClick={onChangeSortBy('name')} is_asc={sortOrder === 'ASC'} is_sorted={sortBy === 'name'}>
                  <Row align='flex-start' justify='flex-start' w='100%'>
                    <span className='heading-span'>Name</span> <ArrowDown />
                  </Row>
                </TH>
                <TH className='sorted start-align' onClick={onChangeSortBy('chain')} is_asc={sortOrder === 'ASC'} is_sorted={sortBy === 'chain'}>
                  <Row align='flex-start' justify='flex-start' w='100%'>
                    <span className='heading-span'>Chain</span> <ArrowDown />
                  </Row>
                </TH>
                <TH className='sorted start-align' onClick={onChangeSortBy('created')} is_asc={sortOrder === 'ASC'} is_sorted={sortBy === 'created'}>
                  <Row align='flex-start' justify='flex-start' w='100%'>
                    <span className='heading-span'>Created</span> <ArrowDown />
                  </Row>
                </TH>
                <TH className='sorted start-align' onClick={onChangeSortBy('updatedAt')} is_asc={sortOrder === 'ASC'} is_sorted={sortBy === 'updatedAt'}>
                  <Row align='flex-start' justify='flex-start' w='100%'>
                    <span className='heading-span'>Updated</span> <ArrowDown />
                  </Row>
                </TH>
                <TH className='sorted start-align' onClick={onChangeSortBy('syncedOnBlock')} is_asc={sortOrder === 'ASC'} is_sorted={sortBy === 'syncedOnBlock'}>
                  <Row align='flex-start' justify='flex-start' w='100%'>
                    <span className='heading-span'>Synced On Block</span> <ArrowDown />
                  </Row>
                </TH>
                <TH className='sorted start-align' onClick={onChangeSortBy('blockNumberDifference')} is_asc={sortOrder === 'ASC'} is_sorted={sortBy === 'blockNumberDifference'}>
                  <Row align='flex-start' justify='flex-start' w='100%'>
                    <span className='heading-span'>Block Number Diff.</span> <ArrowDown />
                  </Row>
                </TH>
                <TH className='sorted start-align' onClick={onChangeSortBy('numberOfEvents')} is_asc={sortOrder === 'ASC'} is_sorted={sortBy === 'numberOfEvents'}>
                  <Row align='flex-start' justify='flex-start' w='100%'>
                    <span className='heading-span'>Events</span> <ArrowDown />
                  </Row>
                </TH>
                <TH className='sorted start-align' onClick={onChangeSortBy('server')} is_asc={sortOrder === 'ASC'} is_sorted={sortBy === 'server'}>
                  <Row align='flex-start' justify='flex-start' w='100%'>
                    <span className='heading-span'>Server</span> <ArrowDown />
                  </Row>
                </TH>
                <TH className='sorted start-align' onClick={onChangeSortBy('status')} is_asc={sortOrder === 'ASC'} is_sorted={sortBy === 'status'}>
                  <Row align='flex-start' justify='flex-start' w='100%'>
                    <span className='heading-span'>Status</span> <ArrowDown />
                  </Row>
                </TH>
                <TH className='start-align'>
                  <span>Info</span>
                </TH>
                <TH className='start-align'>
                  <span>Comment</span>
                </TH>
              </tr>
            </thead>
            <tbody>{renderRows()}</tbody>
          </Table>
          {totalPages > 1 ? (
            <PaginatorRow m='0 auto' justify='center' w='100%'>
              <ReactPaginate
                {...{
                  breakLabel: '...',
                  nextLabel: '>',
                  forcePage: page,
                  onPageChange: onPageChange,
                  pageRangeDisplayed: 3,
                  pageCount: totalPages,
                  previousLabel: '<',
                  className: 'paginator',
                }}
              />
            </PaginatorRow>
          ) : null}
        </Fragment>
      ) : (
        <div>No Info</div>
      )}
    </TableContainer>
  );
};

const SubgraphRow = ({
  subgraph: { id, server, chain, name, created, syncedOnBlock, numberOfEvents, blockNumberDifference, updatedAt, status, statusDescription, problem, comment },
  onManualUpdateComment,
  index,
}) => {
  const currentColor = statusColors[status] || statusColors.null;

  const onComment = (e) => {
    const prevComment = comment === null ? '' : comment;
    const nextComment = e.target.innerText;
    if (prevComment !== nextComment) {
      Api.writeSubgraphComment(id, nextComment)
        .then(() => onManualUpdateComment(index, id, nextComment))
        .catch(() => onManualUpdateComment(index, id, prevComment));
    }
  };

  return (
    <tr style={{ background: currentColor + '0d' }}>
      <TD className={'break-word' + (problem ? ' problem' : '')}>{name}</TD>
      <TD className='break-word'>{chain}</TD>
      <TD className='break-word'>{moment(created).format('DD.MM.YYYY, hh:mm A')}</TD>
      <TD className='break-word'>{moment(updatedAt).format('DD.MM.YYYY, hh:mm A')}</TD>
      <TD className='break-word'>{syncedOnBlock}</TD>
      <TD className='break-word'>{blockNumberDifference}</TD>
      <TD className='break-word'>{numberOfEvents}</TD>
      <TD className='break-word'>{server}</TD>
      <TD className='break-word'>
        <Row align='flex-start' justify='flex-start'>
          {statusIcons[status] || statusIcons.null}
          <span style={{ maxWidth: 'calc(100% - 19px)', marginLeft: 7, color: currentColor }}>{status ? capitalizeFirstLetter(status) : 'Unknown'}</span>
        </Row>
      </TD>
      <TD className='break-word'>{statusDescription}</TD>
      <TD key={'comment' + id + Math.random()} className='break-word editable' contentEditable={true} suppressContentEditableWarning={true} onBlur={onComment}>
        {comment}
      </TD>
    </tr>
  );
};
