import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

import { statisticPath } from "App.consts";
import { strategicReportPath } from "components/data.layout/pages/strategicReport/page.consts";

export const ProtectedRoute = ({ children }) => {
  const token = useSelector((s) => s.user.token);

  if (token) return <Navigate to={`/${statisticPath}/${strategicReportPath}`} />;

  return children;
};
