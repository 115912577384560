import { DoughnutChart, Row } from 'components/common';
import { withRequest } from 'components/hoc/withRequest';

export const Transactions = () => {
  const TxByWallet = withRequest(DoughnutChart, { apiMethodName: 'getNumberOfTxsByWallet', width: 'calc(50% - 15px)' });

  const TxByUser = withRequest(DoughnutChart, { apiMethodName: 'getNumberOfTxsByUser', width: 'calc(50% - 15px)' });

  return (
    <Row>
      <TxByWallet />
      <TxByUser />
    </Row>
  );
};
