import { createSlice } from "@reduxjs/toolkit";

import { loginUser } from "store/reducers/user/actions";

export const createState = () => ({
  userInfo: {},
  token: localStorage.getItem("merlin-token") || "",
  role: "",
  isLoad: false,
  error: "",
});

const userSlice = createSlice({
  name: "user",
  initialState: createState(),
  extraReducers: (builder) => {
    builder.addCase(loginUser.pending, (state) => {
      state.isLoad = true;
    });
    builder.addCase(loginUser.fulfilled, (state, action) => {
      const { userInfo, token } = action.payload;
      localStorage.setItem("merlin-token", token);
      state.isLoad = false;
      state.userInfo = userInfo;
      state.token = token;
      state.error = "";
    });
    builder.addCase(loginUser.rejected, (state, action) => {
      state.isLoad = false;
      state.error = action.payload;
    });
  },
  reducers: {
    setRole: (state, { payload }) => {
      state.role = payload;
    },
  },
});

export const { setRole } = userSlice.actions;
export default userSlice.reducer;
