import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { LineChartComponent } from 'components/common';
import { SkelContent, SkeletonBox, ErrorKpi, Row } from 'components/common';
import Api from 'api';

export const PageUsageByType = () => {
  const filters = useSelector((s) => s.filters);
  const [{ data, load, error }, setKpi] = useState({ load: true, error: '', data: { title: '', plots: null } });

  useEffect(() => {
    const controller = new AbortController();
    setKpi((prev) => ({ ...prev, load: true, error: '' }));
    Api.getPageUsageByType(filters, controller.signal)
      .then((data) => setKpi((prev) => ({ ...prev, data, load: false, error: '' })))
      .catch((error) => {
        if (error !== 'canceled') {
          setKpi((prev) => ({ ...prev, load: false, error }));
        }
      });
    return () => controller.abort();
  }, [filters]);

  if (load) return <Skellet />;

  if (error)
    return (
      <Row>
        <ErrorKpi {...{ width: 'calc(50% - 15px)', message: error }} />
        <ErrorKpi {...{ width: 'calc(50% - 15px)', message: error }} />
      </Row>
    );

  return (
    <Row>
      {data.map((kpi, i) => (
        <LineChartComponent {...{ key: kpi.title + i, data: kpi, error, width: 'calc(50% - 15px)' }} />
      ))}
    </Row>
  );
};

const Skellet = () => (
  <Row>
    <SkeletonBox w='calc(50% - 15px)' h='390px' m='0 0 30px' p='16px 20px'>
      <SkelContent h='18px' m='0 0 31px' />
      <SkelContent h='242px' m='0 0 31px' />
    </SkeletonBox>
    <SkeletonBox w='calc(50% - 15px)' h='390px' m='0 0 30px' p='16px 20px'>
      <SkelContent h='18px' m='0 0 31px' />
      <SkelContent h='242px' m='0 0 31px' />
    </SkeletonBox>
  </Row>
);
