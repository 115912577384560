import { Fragment, useMemo, useState } from 'react';
import { Outlet, NavLink } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { useSelector } from 'react-redux';

import { Column, Row, Card, CardHeading, CrossLine, StickyCol, Filters } from 'components/common';

import { createNavigation } from 'components/data.layout/pages/strategicReport/page.consts';
import { TabNavigation, ExpandedBlock } from 'components/data.layout/pages/strategicReport/styled';

export const StrategicReport = () => {
  const { blackPrimary, greyMiddle } = useTheme();
  const role = useSelector((s) => s.user.role);
  const [activeBlock, setActiveBlock] = useState(0);

  const onChangeActiveBlock = (id) => () => setActiveBlock(id);

  const onScrollToCharts = (id) => () => {
    document.querySelector(`#${id}`).scrollIntoView({ behavior: 'smooth' });
  };

  const tabNavigation = useMemo(
    () =>
      createNavigation(blackPrimary, role === 'MARKETING_AGENCY').map(({ text, to, icon, children }, i) => (
        <Fragment key={to}>
          <NavLink
            to={to}
            className={({ isActive }) => (isActive ? 'active-tab clickable' : 'clickable')}
            is_expanded={+(activeBlock === i + 1)}
            onClick={onChangeActiveBlock(i + 1)}
          >
            {icon} {text}
          </NavLink>
          <Column p='0 0 0 28px'>
            {children.map((el) => (
              <CardHeading m='0 0 8px' fw='500' key={el.text} color={greyMiddle} pointer onClick={onScrollToCharts(el.id)}>
                {el.text}
              </CardHeading>
            ))}
          </Column>
        </Fragment>
      )),
    [blackPrimary, greyMiddle, activeBlock, role]
  );

  return (
    <Row position='relative'>
      <StickyCol w='323px'>
        <Card p='20px 25px' visible>
          <Filters {...{ is_expanded: +(activeBlock === 0), onExpand: onChangeActiveBlock(0) }} />
          <CrossLine />
          <ExpandedBlock is_expanded={+(activeBlock === 1)}>
            <TabNavigation>{tabNavigation}</TabNavigation>
          </ExpandedBlock>
        </Card>
      </StickyCol>
      <Column w='calc(100% - 353px)'>
        <Outlet />
      </Column>
    </Row>
  );
};
