import styled, { keyframes } from 'styled-components';

const shimmer = keyframes`  100% {
    transform: translateX(100%);
  }`;

export const SkeletonBox = styled.div`
  display: inline-block;
  height: ${({ h = '70px' }) => h};
  width: ${({ w = '100%' }) => w};
  margin: ${({ m = 0 }) => m};
  padding: ${({ p = '20px 25px 30px' }) => p};
  position: relative;
  overflow: hidden;
  background-color: #fff;
  border-radius: 4px;
  box-sizing: border-box;
  border: 1px solid ${({ theme: { greyBorder } }) => greyBorder};
  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background: linear-gradient(0.25turn, transparent, #f7f9fb, transparent);
    animation: ${shimmer} 1.5s infinite;
    content: '';
  }
`;

export const SkelContent = styled.div`
  height: ${({ h = '20px' }) => h};
  width: ${({ w = '100%' }) => w};
  margin: ${({ m = 0 }) => m};
  background: #f1f3f8;
  border-radius: 4px;
`;

export const SkelCircle = styled.div`
  height: ${({ size = '42px' }) => size};
  width: ${({ size = '42px' }) => size};
  margin: ${({ m = 0 }) => m};
  background: #f1f3f8;
  border-radius: 50%;
`;
