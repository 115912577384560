import { DoughnutChart, Row } from 'components/common';
import { withRequest } from 'components/hoc/withRequest';

export const Holdings = () => {
  const HoldingsWallet = withRequest(DoughnutChart, { apiMethodName: 'getHoldingsByWallet', width: 'calc(50% - 15px)' });

  const HoldingsUser = withRequest(DoughnutChart, { apiMethodName: 'getHoldingsByUser', width: 'calc(50% - 15px)' });

  return (
    <Row>
      <HoldingsWallet />
      <HoldingsUser />
    </Row>
  );
};
