import React, { useEffect } from "react";

import { LayoutTransp } from "components/common";

export const ModalBg = ({ children }) => {
  useEffect(() => {
    document.body.style.overflow = "hidden";

    return () => (document.body.style.overflow = "visible");
  }, []);
  return <LayoutTransp p="50px 30px">{children}</LayoutTransp>;
};
