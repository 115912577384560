import moment from 'moment';
import { createLegend, createScales, tooltip } from 'helpers';

const createColor = () => '#' + (((1 << 24) * Math.random()) | 0).toString(16);

export const createBarOptions = (obj, fullView, colors = [], datalabels = {}, stackedX = false) => {
  const labels = obj.labels;
  const datasets = JSON.parse(JSON.stringify(obj.datasets));

  datasets.forEach((ds, i, { length }) => {
    const barColors = ds.data.map((_, i) => colors[i] || createColor());
    ds.type = ds.type.toLowerCase();
    ds.backgroundColor = barColors;
    ds.borderColor = barColors;
    ds.xAxisID = 'x';
    ds.borderWidth = 1;
    ds.fill = false;
    ds.order = length - i;
    if (labels.length <= 10) {
      ds.barThickness = 30;
    }
  });

  const options = {
    maintainAspectRatio: false,
    layout: {
      padding: {
        top: 15,
      },
    },
    interaction: {
      mode: 'index',
      intersect: false,
    },
    stacked: true,
    plugins: {
      legend: createLegend(fullView),
      tooltip,
      datalabels,
    },
    scales: createScales(fullView, stackedX, false),
  };

  return {
    data: {
      labels,
      datasets,
    },
    options,
    className: 'bar-line-chart',
  };
};

export const createDatalabels = (fs = 12) => ({
  anchor: ({ dataIndex, dataset: { data } }) => {
    const total = data.reduce((acc, el) => (acc += el), 0);
    const percentage = (100 * data[dataIndex]) / total;
    return percentage > 4 ? 'center' : 'end';
  },
  align: ({ dataIndex, dataset: { data } }) => {
    const total = data.reduce((acc, el) => (acc += el), 0);
    const percentage = (100 * data[dataIndex]) / total;
    return percentage > 4 ? 'center' : 'top';
  },
  display: ({ dataIndex, dataset: { data } }) => {
    const total = data.reduce((acc, el) => (acc += el), 0);
    const percentage = (100 * data[dataIndex]) / total;
    return percentage > 0;
  },
  rotation: 270,
  color: '#181818',
  formatter: (_, { dataIndex, dataset: { data } }) => {
    const total = data.reduce((acc, el) => (acc += el), 0);
    return ((100 * data[dataIndex]) / total).toFixed(1) + '%';
  },
  font: {
    family: 'Montserrat',
    size: fs,
    weight: 600,
  },
});

export const createMultipleBarOptions = (obj, fullView, colors = []) => {
  const labels = obj.labels.map((el) => moment(el).format('DD MMM YYYY'));
  const datasets = JSON.parse(JSON.stringify(obj.datasets));
  datasets.forEach((ds, i, { length }) => {
    const barColors = colors[i] || createColor();
    ds.type = ds.type.toLowerCase();
    ds.backgroundColor = barColors;
    ds.borderColor = barColors;
    ds.xAxisID = 'x';
    ds.borderWidth = 1;
    ds.fill = false;
    ds.order = length - i;
    if (labels.length <= 10) {
      ds.barThickness = 30;
    }
  });

  const options = {
    maintainAspectRatio: false,
    layout: {
      padding: {
        top: 15,
      },
    },
    interaction: {
      mode: 'index',
      intersect: false,
    },
    stacked: true,
    plugins: {
      legend: createLegend(fullView),
      tooltip: {
        ...tooltip,
        filter: (item) => item.raw,
        itemSort: (a, b) => b.raw - a.raw,
      },
    },
    scales: createScales(fullView, true, false),
  };

  return {
    data: {
      labels,
      datasets,
    },
    options,
    className: 'bar-line-chart',
  };
};
