import { SyncronizeIcon, SyncronizedIcon, PausedIcon, QuestionIcon, FailedIcon } from 'components/icons';

export const subgraphsInfoPath = 'subgraphs-info';

export const statusColors = {
  syncing: '#0000ff',
  synced: '#008000',
  paused: '#ffa500',
  failed: '#ff0000',
  null: '#808080',
};

export const statusIcons = {
  syncing: <SyncronizeIcon />,
  synced: <SyncronizedIcon />,
  paused: <PausedIcon />,
  failed: <FailedIcon />,
  null: <QuestionIcon />,
};

export const FilterTypes = {
  Prod: 'prod',
  'Prod V2': 'prodV2',
  Dev: 'dev',
  Indexed: 'indexed',
  'Indexed Soon': 'indexed_soon',
  Problem: 'problem',
  Errors: 'errors',
  'Dev 2': 'dev2',
  All: null,
};
