import styled from "styled-components";

export const TabNavigation = styled.nav`
  display: flex;
  flex-direction: column;
  h3 {
    cursor: pointer;
    &:hover {
      color: ${({ theme: { blue } }) => blue};
    }
  }
  a {
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 15px;
    color: ${({ theme: { blackPrimary } }) => blackPrimary};
    opacity: 0.5;
    svg {
      margin-right: 12px;
    }
    &.active-tab {
      opacity: 1;
    }
  }
`;

export const ExpandedBlock = styled.div`
  .clickable {
    cursor: ${({ is_expanded = 0 }) => (is_expanded ? "auto" : "pointer")};
    &:hover {
      color: ${({ is_expanded = 0, theme: { blue, blackPrimary } }) => (is_expanded ? blackPrimary : blue)};
      h3 {
        color: ${({ is_expanded = 0, theme: { blue, blackPrimary } }) => (is_expanded ? blackPrimary : blue)};
      }
      svg {
        path {
          /* stroke: ${({ is_expanded = 0, theme: { blue, blackPrimary } }) => (is_expanded ? blackPrimary : blue)}; */
          fill: ${({ is_expanded = 0, theme: { blue, blackPrimary } }) => (is_expanded ? blackPrimary : blue)};
        }
      }
    }
  }
  height: ${({ is_expanded = 0 }) => (is_expanded ? "fit-content" : "22px")};
  overflow: ${({ is_expanded = 0 }) => (is_expanded ? "visible" : "hidden")};
`;
