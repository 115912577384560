import { useMemo, useEffect, Fragment } from 'react';
import { NavLink, Outlet } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Header, VerticalBorder, Wrapper, Navigation } from 'data.panel/styled';
import { Merlin } from 'components/icons';
import { Button, VerticalLine, Loader } from 'components/common';
import { navigation, adminNavigation } from 'data.panel/data.app.const';
import { ProtectedRoute } from 'data.panel/protectedRoute';
import { setAppOptions, setAppError } from 'store/reducers/options';
import { setRole } from 'store/reducers/user';
import Api from 'api';

export const DataApp = () => {
  const dispatch = useDispatch();
  const { appError, isAppReady } = useSelector((s) => s.options);
  const role = useSelector((s) => s.user.role);

  useEffect(() => {
    Promise.all([Api.getWalletConnectOptions(), Api.getUniqueWalletsList(), Api.getMarketingCompanies(), Api.getRole()])
      .then(([walletConnectOptions, uniqueWalletsList, marketingCompanies, roleInfo]) => {
        dispatch(setAppOptions({ walletConnectOptions, uniqueWalletsList, marketingCompanies }));
        dispatch(setRole(roleInfo.role));
      })
      .catch((err) => dispatch(setAppError(err)));
    // eslint-disable-next-line
  }, []);

  const onLogout = () => dispatch({ type: 'reset-store' });

  const headerNavigation = useMemo(
    () =>
      (role === 'ADMIN' ? adminNavigation : navigation).map(({ text, icon, to }) => (
        <NavLink className={({ isActive }) => (isActive ? 'is_active' : undefined)} to={to} key={to + text}>
          {icon}
          {text}
        </NavLink>
      )),
    [role]
  );

  return (
    <ProtectedRoute>
      {isAppReady ? (
        <Fragment>
          <Header>
            <Merlin />
            <VerticalBorder />
            <h6>STATISTIC</h6>
            <VerticalLine parentPadding={0} m='30px' />
            <Navigation>{headerNavigation}</Navigation>
            <Button m='0 0 0 auto' onClick={onLogout}>
              LOGOUT
            </Button>
          </Header>
          <Wrapper p='30px'>
            <Outlet />
          </Wrapper>
        </Fragment>
      ) : appError ? (
        <div>{appError}</div>
      ) : (
        <Loader />
      )}
    </ProtectedRoute>
  );
};
