import styled, { css } from "styled-components";

import { fields } from "components/common/select/styled";
import { transition } from "components/common/mixins";
import { DarkText, Column } from "components/common";

const inputs = css`
  ${fields}
  ${transition};
  height: 42px;
  cursor: auto;
  outline: none;
  font-weight: normal;
  color: ${({ theme: { blackPrimary } }) => blackPrimary};
  &:focus {
    border-color: ${({ theme: { blue } }) => blue};
  }
`;

const Input = styled.input`
  ${inputs}
`;

export const InputComponent = ({ width = "100%", margin, placeholder, onChange = console.log, value = "", label, name, type = "text" }) => {
  return (
    <Column w={width} m={margin}>
      <DarkText fw={500} fs={10} uppercase m="0 0 8px">
        {label || name}
      </DarkText>
      <Input {...{ placeholder, onChange, value, name, type }} />
    </Column>
  );
};
