import { Fragment, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { DoughnutChart } from 'components/common';
import { Row, SkelContent, SkeletonBox, SkelCircle, ErrorKpi } from 'components/common';
import Api from 'api';

export const RecencyAndAge = ({ apiMethodName = 'getRecencyAndAge' }) => {
  const filters = useSelector((s) => s.filters);
  const [{ data, load, error }, setKpi] = useState({ load: true, error: '', data: { title: '', plots: null } });

  useEffect(() => {
    const controller = new AbortController();
    setKpi((prev) => ({ ...prev, load: true, error: '' }));
    Api[apiMethodName](filters, controller.signal)
      .then((data) => setKpi((prev) => ({ ...prev, data, load: false, error: '' })))
      .catch((error) => {
        if (error !== 'canceled') {
          setKpi((prev) => ({ ...prev, load: false, error }));
        }
      });
    return () => controller.abort();
  }, [filters, apiMethodName]);

  if (load) return <Skellet />;

  if (error) return <ErrorKpi message={error} w='calc(50% - 15px)' />;

  return (
    <Fragment>
      <Row isWrap>
        {data.map((kpi, i) => (
          <DoughnutChart {...{ key: kpi.title + i, kpi }} />
        ))}
      </Row>
    </Fragment>
  );
};

const Skellet = () => (
  <Row isWrap>
    <SkeletonBox w='calc(50% - 15px)' h='390px' m='0 0 30px' p='16px 20px'>
      <SkelContent h='18px' m='0 0 31px' />
      <SkelCircle size='180px' m='0 auto' />
      <SkelContent h='36px' m='24px 0 16px' />
      <SkelContent h='36px' />
    </SkeletonBox>
    <SkeletonBox w='calc(50% - 15px)' h='390px' m='0 0 30px' p='16px 20px'>
      <SkelContent h='18px' m='0 0 31px' />
      <SkelCircle size='180px' m='0 auto' />
      <SkelContent h='36px' m='24px 0 16px' />
      <SkelContent h='36px' />
    </SkeletonBox>
    <SkeletonBox w='calc(50% - 15px)' h='390px' m='0 0 30px' p='16px 20px'>
      <SkelContent h='18px' m='0 0 31px' />
      <SkelCircle size='180px' m='0 auto' />
      <SkelContent h='36px' m='24px 0 16px' />
      <SkelContent h='36px' />
    </SkeletonBox>
    <SkeletonBox w='calc(50% - 15px)' h='390px' m='0 0 30px' p='16px 20px'>
      <SkelContent h='18px' m='0 0 31px' />
      <SkelCircle size='180px' m='0 auto' />
      <SkelContent h='36px' m='24px 0 16px' />
      <SkelContent h='36px' />
    </SkeletonBox>
  </Row>
);
