import { DoughnutChart } from 'components/common';
import { useEffect, useState, Fragment } from 'react';
import { useSelector } from 'react-redux';
import DataTable from 'react-data-table-component';

import { SkelCircle, SkelContent, SkeletonBox, ErrorKpi, ModalBg, Row, Button, Card, CardHeading, Loader, DarkText } from 'components/common';
import { Cross } from 'components/icons';
import Api from 'api';

const countMap = {
  'Less or equal than 1': 1,
  '2-5': 2,
  '6-10': 6,
  'More than 11': 11,
};

export const AverageSessionPerUserDistribution = () => {
  const filters = useSelector((s) => s.filters);
  const [{ data, load, error }, setKpi] = useState({ load: true, error: '', data: { title: '', plots: null } });
  const [count, setCount] = useState(0);

  useEffect(() => {
    const controller = new AbortController();
    setKpi((prev) => ({ ...prev, load: true, error: '' }));
    Api.getAverageSessionPerUserDistribution(filters, controller.signal)
      .then((data) => setKpi((prev) => ({ ...prev, data, load: false, error: '' })))
      .catch((error) => {
        if (error !== 'canceled') {
          setKpi((prev) => ({ ...prev, load: false, error }));
        }
      });
    return () => controller.abort();
  }, [filters]);

  const getElementAtEvent = ([{ index }]) => {
    const label = data.plots.Total.labels[index];
    setCount(countMap[label.trim()]);
  };

  if (load)
    return (
      <SkeletonBox w='calc(34% - 15px)' h='390px' m='0 0 30px' p='16px 20px'>
        <SkelContent h='18px' m='0 0 31px' />
        <SkelCircle size='180px' m='0 auto' />
        <SkelContent h='36px' m='24px 0 16px' />
        <SkelContent h='36px' />
      </SkeletonBox>
    );

  if (error) return <ErrorKpi message={error} width='calc(34% - 15px)' />;

  return (
    <Fragment>
      <DoughnutChart width='calc(34% - 15px)' kpi={data} chartProps={{ getElementAtEvent }} />
      {count ? <ModalDetails {...{ count, setCount, filters, title: data.title }} /> : null}
    </Fragment>
  );
};

const ModalDetails = ({ count = '', setCount, filters, title }) => {
  const [data, setData] = useState(null);

  useEffect(() => {
    const controller = new AbortController();

    Api.getDeviceIdBySession(filters, count, controller.signal).then(setData).catch(console.log);
  }, [count, filters]);

  const columns = [
    {
      name: 'Wallets',
      selector: (row) => row.wallets.length,
      sortable: true,
    },
    {
      name: 'Ips',
      selector: (row) => row.ips.length,
      sortable: true,
    },
    {
      name: 'Countries',
      selector: (row) => row.countries.length,
      sortable: true,
    },
    {
      name: 'Count',
      selector: (row) => row.count,
      sortable: true,
    },
  ];

  return (
    <ModalBg>
      <Card h='100%' p='30px' w='700px' m='auto'>
        <Row position='relative' h='24px' w='100%' m='0 0 20px'>
          <CardHeading>{title}</CardHeading>
          <Button no_text is_fill w='24px' z={3} h='24px' onClick={() => setCount(0)}>
            <Cross color='#fff' />
          </Button>
        </Row>
        {data ? (
          <div style={{ height: 'calc(100% - 44px)', overflow: 'auto' }}>
            <DataTable
              dense
              fixedHeader
              highlightOnHover
              columns={columns}
              data={data}
              expandableRows
              expandableRowsComponent={ExpandedComponent}
              pagination
              paginationPerPage={15}
              paginationRowsPerPageOptions={[15, 20, 30]}
            />
          </div>
        ) : (
          <Loader />
        )}
      </Card>
    </ModalBg>
  );
};

const ExpandedComponent = ({ data: { deviceId, countries, ips, wallets } }) => (
  <div
    style={{
      border: '1px solid rgba(0,0,0,.12)',
      borderTop: 0,
    }}
  >
    <Card p='12px' w='638px'>
      <DarkText m='0 0 6px'>Device ID</DarkText>
      <p style={{ fontSize: 12, margin: '0 0 12px' }}>{deviceId}</p>
      <DarkText m='0 0 6px'>Countries</DarkText>
      <p style={{ fontSize: 12, margin: '0 0 12px' }}>{countries.join(', ')}</p>
      <DarkText m='0 0 6px'>Wallets</DarkText>
      <p style={{ fontSize: 12, margin: '0 0 12px' }}>{wallets.join(', ')}</p>
      <DarkText m='0 0 6px'>IP's</DarkText>
      <p style={{ fontSize: 12, margin: '0 0 12px' }}>{ips.join(', ')}</p>
    </Card>
  </div>
);
